import React from "react";
import styles from "./index.module.scss";
import currency from "../../utils/currency";
import properNum from "../../utils/properNum";
const CurrencyInput = ({ name, num, onchange, holder, mid, focus }) => {
  return (
    <div className={styles.currencyInput}>
      <input
        type="text"
        name={name}
        onChange={onchange}
        value={num ? currency(properNum(num), true) : ""}
        placeholder={holder || "0"}
        className={mid ? styles.mid : ""}
        autoFocus={focus}
      />
      <span className={mid ? styles.mid : ""}>&#8358;</span>
    </div>
  );
};

export default CurrencyInput;
