import Axios from 'axios';
import { setNotify } from './notify';
import { uploadRequest } from './auth';

export const uploadResume = (
  { name, role, introduction, cv },
  action
) => dispatch => {
  dispatch(uploadRequest(true));
  let formData = new FormData();
  formData.set('name', name);
  formData.set('role', role);
  formData.set('introduction', introduction);
  formData.set('cv', cv);
  return Axios.post(
    'https://analytics.firsthomesafrica.com/api/admin/apply',
    formData
  )
    .then(({ data }) => {
      dispatch(uploadRequest(false));

      dispatch(
        setNotify({
          title: 'Upload Status',
          body: 'Your Resumè has been Uploaded',
          type: 'success'
        })
      );
      action && action();
    })
    .catch(error => {
      dispatch(uploadRequest(false));
    });
};
