/* eslint-disable no-unused-expressions */
import './App.scss';

import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import React from 'react';
import About from './pages/About';
import ArticleView from './pages/ArticleView';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import OnBoarding from './pages/OnBoarding';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import SignupInfluencer from './pages/SignupInfluencer';
import SignupTwo from './pages/SignUpTwo';
import Notify from './components/Notify';
import ProtectedRoute from './components/ProtectedRoute';
import Verify from './pages/Verify';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import howItWorks from './pages/howItWorks';
import Bonus from './pages/Bonus';
import Accelerate from './pages/Accelerate';
import Gallery from './pages/Gallery';
import FormalComplaint from './pages/FormalComplaint';
import FormalComplaintContact from './pages/FormalComplaintContact';
import FAQs from './pages/FAQs';
import Career from './pages/Career';
import YourHome from './pages/YourHome';
import CareerApply from './pages/CareerApply';
import ForgetPassword from './pages/ForgetPassword';
import ScrollToTop from './components/ScreenToTop';
import IdleTimer from 'react-idle-timer';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { logoutUser } from './store/actions/auth';
import OnBoardRoute from './components/onBoardRoute';
const App = () => {
  const dispatch = useDispatch();
  const user = useMappedState(({ auth }) => auth.currentUser);

  window.addEventListener('beforeunload', ev => {
    ev.preventDefault();
    if (user && user.hasPassword) {
      localStorage.setItem('lastVisited', new Date());
      return;
    }
  });
  return (
    <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
      <ScrollToTop>
        <IdleTimer
          element={document}
          onIdle={() =>
            user && user.hasPassword ? dispatch(logoutUser()) : null
          }
          timeout={1000 * 60 * 3}
        />
        <Notify />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/blog' component={Blog} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/careers' component={Career} />
          <Route exact path='/careers/apply' component={CareerApply} />
          <Route exact path='/blog/:id' component={ArticleView} />
          <Route exact path='/about' component={About} />
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/faqs' component={FAQs} />
          <Route exact path='/complaint' component={FormalComplaint} />
          <Route exact path='/yourhome' component={YourHome} />
          <Route
            exact
            path='/complaint/contact'
            component={FormalComplaintContact}
          />
          <Route exact path='/terms' component={Terms} />
          <Route
            path='/how-it-works'
            exact
            render={() => <Redirect to='/how-it-works/unique' />}
          />
          <Route path='/how-it-works/:feature' component={howItWorks} />
          <Route exact path='/signin' component={Signin} />
          <Route exact path='/gallery' component={Gallery} />
          <Route exact path='/forgot-password' component={ForgetPassword} />
          <Route exact path='/bonus' component={Bonus} />
          <Route exact path='/accelerate' component={Accelerate} />
          <Route exact path='/signup' component={Signup} />
          <Route exact path='/signup2' component={SignupTwo} />
          <Route exact path='/signup/influencer' component={SignupInfluencer} />
          <OnBoardRoute exact path='/onboarding' component={OnBoarding} />
          <ProtectedRoute path='/dashboard' component={Dashboard} />
          <Route path='/verify' component={Verify} />
          <Route component={NotFound} />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default App;
