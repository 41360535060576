import React, { useState } from 'react';
import styles from './index.module.scss';

import PropTypes from 'prop-types';

const propTypes = {
	children: PropTypes.array.isRequired,
};

const Tabs = ({ children }) => {
	const [ current, setCurrent ] = useState(0);
	return (
		<div className={styles.tabs}>
			<div className={styles.tabItems}>
				{children.map((component, index) => {
					return (
						<div
							key={index}
							className={`${styles.tab} ${current === index && styles.active}`}
							onClick={() => setCurrent(index)}>
							{component.props.title}
						</div>
					);
				})}
			</div>
			<div className={styles.tabView}>
				{children[current]}
			</div>
		</div>
	);
};

Tabs.propTypes = propTypes;

export default Tabs;
