import React, { useState } from "react";

import styles from "./index.module.scss";
import spinner from "../../assets/spinner.svg";
import navLogo from "../../assets/navLogo.svg";
import { useDispatch, useMappedState } from "redux-react-hook";
import { Link } from "react-router-dom";
import { forgotPassword, sendOtp, setPassword } from "../../store/actions/auth";
import includesAll from "../../utils/includesAll";
import { setNotify } from "../../store/actions/notify";
import eye from "../../assets/eye.svg";
import eyecl from "../../assets/eyecl.svg";

const ForgetPassword = ({ history }) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  const [current, setCurrent] = useState(0);
  const user = useMappedState(state => state.auth);
  const [otp, setOtp] = useState("");
  const [passwords, setPasswords] = useState({});
  const [show, setShow] = useState(false);

  const onChange = e => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value
    });
  };

  const passwordSet = e => {
    e && e.preventDefault();
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value
    });
  };
  const submit = e => {
    e.preventDefault();
    if (!includesAll(Object.keys(details), ["phoneNumber"])) {
      return dispatch(
        setNotify({
          type: "error",
          title: "Phone number is required",
          body: "Insert all fields correctly"
        })
      );
    }
    dispatch(forgotPassword(details, () => setCurrent(1)));
  };

  const stages = {
    0: (
      <form onSubmit={submit} autoComplete="off">
        <input
          autoComplete="new-password"
          type="phone"
          name="phoneNumber"
          onChange={onChange}
          placeholder="Phone number"
          maxLength="11"
        />
        <button
          type="submit"
          className={styles.submit}
          value={spinner}
          disabled={user.loading}
        >
          {user.loading ? (
            <img src={spinner} alt="" />
          ) : (
            <span>RESET PASSWORD</span>
          )}
        </button>
      </form>
    ),
    1: (
      <form
        onSubmit={e => {
          e.preventDefault();
          dispatch(sendOtp({ otp }, () => setCurrent(2), true));
        }}
        autoComplete="off"
      >
        <input
          autoComplete="new-password"
          type="phone"
          name="otp"
          value={otp}
          onChange={e => {
            e.preventDefault();
            setOtp(e.target.value);
          }}
          placeholder="Enter OTP"
          maxLength="4"
        />
        <button
          type="submit"
          className={styles.submit}
          value={spinner}
          disabled={user.loading}
        >
          {user.loading ? <img src={spinner} alt="" /> : <span>ENTER OTP</span>}
        </button>
      </form>
    ),
    2: (
      <form
        onSubmit={e => {
          e.preventDefault();
          dispatch(setPassword(passwords, () => history.push("signin"), true));
        }}
        autoComplete="off"
      >
        <div className={styles.passInput}>
          <input
            autoComplete="new-password"
            type={show ? "text" : "password"}
            name="password"
            placeholder="Enter password"
            value={passwords.password || ""}
            onChange={passwordSet}
          />
          <img
            alt="show password"
            src={show ? eye : eyecl}
            className={styles.inbtn}
            onClick={() => setShow(!show)}
          />
        </div>
        <input
          autoComplete="new-password"
          type={show ? "text" : "password"}
          name="confirmPassword"
          placeholder="Confirm Password"
          value={passwords.confirmPassword || ""}
          onChange={passwordSet}
        />
        <button
          type="submit"
          className={styles.submit}
          value={spinner}
          disabled={user.loading}
        >
          {user.loading ? (
            <img src={spinner} alt="" />
          ) : (
            <span>ENTER NEW PASSWORD</span>
          )}
        </button>
      </form>
    )
  };
  return (
    <div className={styles.forgot}>
      <div className={styles.main}>
        <div className={styles.formGroup}>
          <Link to="/">
            <img className={styles.logo} src={navLogo} alt="" />
          </Link>
          <div className={styles.head1}>Forgot password</div>
          <div className={styles.head2}>
            Please enter your phone number below and we will send you
            instructions on how to reset your password
          </div>

          {stages[current]}
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
