import { Route, Switch } from 'react-router-dom';
import React from 'react';

import Account from './Account';
import DashboardHome from './Home';
import Nav from '../../components/Nav';
import Sidebar from '../../components/Sidebar';
import Vault from './Vault';
import Save from './Save';
import BlockWise from './BlockWise';
import Gallery from './Gallery';
import styles from './index.module.scss';

const Dashboard = ({ match }) => {
  const Test2 = () => (
    <div style={{ padding: '50px', fontSize: '20px', textAlign: 'center' }}>
      Coming soon
    </div>
  );
  const TestNo = () => <div>Text page not</div>;
  return (
    <div className={styles.dashboard}>
      <Nav onlyHome showBackground />
      <Sidebar url={match.url} />
      <div className={styles.dashView}>
        <Switch>
          <Route path={`${match.path}`} exact component={DashboardHome} />
          <Route path={`${match.path}/vault`} component={Vault} />
          <Route path={`${match.path}/save-now`} component={Save} />
          <Route path={`${match.path}/account`} component={Account} />
          <Route path={`${match.path}/blockwise`} component={BlockWise} />
          <Route path={`${match.path}/marketplace`} component={Test2} />
          <Route path={`${match.path}/gallery`} component={Gallery} />
          <Route component={TestNo} />
        </Switch>
      </div>
    </div>
  );
};
export default Dashboard;
