export const ADD_CARD_SUCCESS = "ADD_CARD_SUCCESS";
export const ADD_CARD_FAILURE = "ADD_CARD_FAILURE";
export const ADD_CARD_LOADING = "ADD_CARD_LOADING";
export const ADD_CARD_PIN = "ADD_CARD_PIN";
export const ADD_CARD_OTP = "ADD_CARD_OTP";
export const ADD_CARD_ADDRESS = "ADD_CARD_ADDRESS";
export const ADD_CARD_URL = "ADD_CARD_URL";
export const ALL_CARD_LOADING = "ALL_CARD_LOADING";
export const ALL_CARD_SUCCESS = "ALL_CARD_SUCCESS";
export const ALL_CARD_FAILURE = "ALL_CARD_FAILURE";
