import React, { useState } from "react";
import styles from "./index.module.scss";
import up from "../../assets/chev-up.svg";
import down from "../../assets/chev-down.svg";

const Accordian = ({ title, body, last }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.fold}>
      <div
        className={`${styles.title} ${last || open ? styles.last : ""}`}
        onClick={() => setOpen(!open)}
      >
        {title}{" "}
        <span>
          {" "}
          <img src={open ? up : down} alt="" />
        </span>
      </div>
      {open && <div className={styles.body}>{body}</div>}
    </div>
  );
};

export default Accordian;
