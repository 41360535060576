import {
  GET_HOUSE_TYPES,
  GET_STATES,
  GET_LOCATIONS,
  LOCATION_LOADING,
  CALCULATING,
  GET_CALCULATION,
  FETCH_CALCULATION,
  FETCH_DOWN_PAY,
  FETCH_DOWN_PAY_STATUS
} from "../actions/types/onboard";

const INITIAL_STATE = {
  states: [],
  locations: [],
  houseTypes: [],
  loading: false,
  calculating: false,
  calculation: null,
  downPaymentDetails: {},
  downPaymentStatus: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_STATES:
      return {
        ...state,
        states: action.states
      };

    case GET_HOUSE_TYPES:
      return {
        ...state,
        houseTypes: action.houses
      };
    case GET_LOCATIONS:
      return {
        ...state,
        locations: action.locations,
        loading: false
      };
    case LOCATION_LOADING:
      return {
        ...state,
        loading: true
      };
    case CALCULATING:
      return {
        ...state,
        calculation: null,
        calculating: action.kind
      };
    case GET_CALCULATION:
      return {
        ...state,
        calculating: false,
        calculation: action.calculation
      };
    case FETCH_CALCULATION:
      return {
        ...state,
        calculating: false,
        calculation: action.calculation
      };
    case FETCH_DOWN_PAY:
      return {
        ...state,
        downPaymentDetails: action.data
      };
    case FETCH_DOWN_PAY_STATUS:
      return {
        ...state,
        downPaymentStatus: action.data
      };
    default:
      return state;
  }
};
