import { SET_NOTIFY, CLOSE_NOTIFY } from "../actions/types/notify";

const INITIAL_STATE = {
  messages: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_NOTIFY:
      return {
        ...state,
        messages: [...state.messages, action.message]
      };

    case CLOSE_NOTIFY:
      const messages = [...state.messages];
      messages.splice(action.index, 1);

      return {
        ...state,
        messages
      };
    default:
      return state;
  }
};
