export default (dt2, dt1, type) =>  {

 var diff =(new Date(dt2).getTime() - new Date(dt1).getTime()) / 1000;
  diff /= (60 * 60 * 24 * 4.35 * 7);
 
  
 if(type==='month'){
   return Math.floor(diff);
 }
  
  if(type==='day'){
    let d = diff % 1;
    d = d * 4.6 * 7;
   return Math.floor(d);
 }
 return diff;
}