import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import Footer from '../../components/Footer'; import Whatsapp from '../../components/Whatsapp';
// import ImgDesc from '../../components/ImgDesc';
import Nav from '../../components/Nav';
// import phone from '../../assets/phone.png';
import pig from '../../assets/pig.svg';
import face from '../../assets/face-recognition.svg';
import chv from '../../assets/chv-lg.svg';
// import dashboard from "../../assets/dashboard.svg";
import styles from './index.module.scss';
// import banner1 from '../../assets/banner.png';
// import banner2 from '../../assets/banner2.png';
// import banner3 from '../../assets/banner3.png';
import videoAd from '../../assets/video.mp4';
// import banner4 from '../../assets/banner4.png';
// import poster from '../../assets/adposter.jpeg';
import slide1 from '../../assets/1.jpeg';
import slide2 from '../../assets/Sitting_Room.jpg';
import slide3 from '../../assets/3.jpeg';
import control from '../../assets/control.svg';
import controlgreen from '../../assets/controlgreen.svg';
import flex from '../../assets/flex.svg';
import flexhover from '../../assets/flex1.svg';
import search from '../../assets/search.svg';
import searchgreen from '../../assets/search1.svg';
import touchid from '../../assets/touch.svg';
import touchgreen from '../../assets/touchgreen.svg';
// import ios from '../../assets/appstore.svg';
import hold from '../../assets/hold.svg';
// import android from '../../assets/playstore.jpg';
import realestate from '../../assets/real-estate.svg';
// import Dangote from '../../assets/partners/Dangote.png';
// import aiico from '../../assets/partners/aiico.png';

// import xclose from '../../assets/x.svg';
// import FullBannerVideo from '../../components/FullBannerVideo';
// import nimc from "../../assets/partners/nimc.png";
// import nmrc from "../../assets/partners/nmrc.png";
// import zenithI from '../../assets/partners/zenithinsurance.png';
// import gtb from '../../assets/partners/gtb.png';
// import fbn from '../../assets/partners/fbn.png';
// import abb from '../../assets/partners/abb.png';
// import tower from '../../assets/partners/tower.jpg';
// import lotus from "../../assets/partners/lotus.png";
// import staco from "../../assets/partners/staco.svg";
// import swiss from '../../assets/partners/swiss.jpg';
// import sterling from '../../assets/partners/sterling.svg';
// import bagi from '../../assets/partners/bagi.svg';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';

const YourHome = () => {
  const [modal, setModal] = useState(false);
  const [current, setCurrent] = useState(0);
  // const [notice, setNotice] = useState(true);
  const banners = {
    0: {
      desc: ' Choose Your Home With Us.',
      banner: slide1
    },
    1: {
      desc: ' Choose Your Home With Us.',
      banner: slide2
    },
    2: {
      desc: ' Choose Your Home With Us.',
      banner: slide3
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const nextPage = () => {
    if (current <= 1) {
      return setCurrent(current + 1);
    }
    if (current === 2) {
      return setCurrent(0);
    }
  };
  const prevPage = () => {
    if (current > 0) {
      return setCurrent(current - 1);
    }
    if (current <= 0) {
      return setCurrent(0);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      nextPage();
    }, 8000);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <div className={styles.home}>
     <Whatsapp />
      <Nav showBackground address />
      <div className={styles.main}>
        {/* {notice && (
          <div className={styles.notice}>
            Grow equity, refer friends to FirstHomes Africa and both of you can earn
            up to N500, 000 per sign up. <Link to="/bonus"> Learn More </Link>
            <img src={xclose} alt="close" onClick={() => setNotice(false)} />
          </div>
        )} */}
        <div
          className={styles.background}
          style={{
            background: `url(${banners[current].banner}) center center / cover no-repeat`
          }}
        >
          <div className={styles.first}>
            <div className={styles.title}> Your Home </div>
            <div className={styles.desc}> {banners[current].desc} </div>
            <div className={styles.buttons}>
              <Link to="/how-it-works" className={styles.dark}>
                LEARN MORE
              </Link>
              <a href="/#appDownload"> Download App </a>
              {/* <Link to="/#appDownload"> Download App </Link> */}
            </div>
          </div>
          <div className={styles.chevs}>
            <img onClick={prevPage} src={chv} alt="" />
            <img onClick={nextPage} src={chv} alt="" />
          </div>
        </div>
      </div>
      {/* <div className={styles.rent}>
        <div className={styles.title}> Make your rent work for you. </div>
        <div className={styles.desc}>
          Real Estate is the guaranteed way to grow your net worth. Why not make
          your rent work for you with our lease-to-own offer where you increase
          your equity as you make your monthly payment ?
        </div>
      </div> */}
      {/* Bottom={() => (
                            <button className={styles.noBg} onClick={() => setModal(true)}>
                              <img src={playBtn} alt="play button"/>
                            </button>
                          )} */}
      {/* <FullBannerVideo content={videoAd} poster={poster} /> */}
      <div className={styles.features}>
        <div className={styles.text}> How it works </div>
        <div className={styles.group}>
          <div className={styles.card}>
            <img src={hold} alt="icon" />
            <div className={styles.head}>
              {" "}
              Register and Create Your Account{" "}
            </div>
            <div className={styles.sub}>
              Help us get to know you better. We want to know what size home you want
              to live in, neighbourhood you prefer to move into and the budget you can
              afford to spend. We build our homes to meet the demand and should we have any
              properties available that meet your requirements, we will let you know. <br/>
              <div className={styles.buttons}>
                <Link to="/signup" className={styles.dark}>
                Sign Up
                </Link>
                <Link to="/gallery" className={styles.dark}> See What’s Available </Link>
              </div>
            </div>
          </div>
          <div className={styles.card}>
            <img src={pig} alt="icon" />
            <div className={styles.head}> Start Saving </div>
            <div className={styles.sub}>
              Work out the monthly contribution you need to make when you sign-up.
              If you can afford the monthly payments and like our terms, we’ll invite you
              to make a first contribution and agree to a contract via our mobile platform.
              All you need to do from here is keep paying your monthly contributions on time
              for the next 11 months in order to qualify for the scheme. <br />
              <div className={styles.buttons}>
                <a href="/terms#link-to" className={styles.dark}> Learn more </a>
              </div>
            </div>
          </div>
          <div className={styles.card}>
            <img src={face} alt="icon" />
            <div>
              <div className={styles.head}> Qualify For Your Brand New Home </div>
              <div className={styles.sub}>
              After 12 months of contributions, our mobile platform allocates you a property
              closest to your registered preference in its final stages of construction.
              The system will take into account your budget and your other requirements
              listed at registration. But we also understand that you might want to qualify
              for your brand new home in less than 12 months.
              Check out how you can Accelerate your home purchase now.{" "}
                <div className={styles.buttons}>
                  <Link target="_blank" to="/accelerate" className={styles.dark}> Accelerate </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.card}>
            <img src={realestate} alt="icon" />
            <div className={styles.head}>
              {" "}
              Sign Our Contract & Move In{" "}
            </div>
            <div className={styles.sub}>
            After you have been allocated your home, the system will offer you a
            contract to lease to own. The system will also upgrade your account with more
            information so that it can better support and facilitate your lease to own
            scheme for the next 10 to 15 years. Once your contract has been finalised,
            you will receive the keys to your brand new home. We’ll even give you a three
            year warranty on all structural repairs and maintenance! <br/>
              <div className={styles.buttons}>
                <a href="/#appDownload" className={styles.dark}> Download App </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={styles.promo}>
          <a target="_blank" href="/accelerate">
            To Accelerate Click Here{" "}
          </a>
        </div> */}
        <div className={styles.textWell}>Why you should choose FirstHomes Africa</div>
        <div className={styles.tabs}>
          <Link
            to="/how-it-works/unique"
            className={styles.tab}
            onMouseEnter={e => {
              if (e.target.children[0]) e.target.children[0].src = touchgreen;
            }}
            onMouseLeave={e => {
              if (e.target.children[0]) e.target.children[0].src = touchid;
            }}
          >
            <img src={touchid} alt="icon" />
            <div> Unique </div>
          </Link>
          <Link
            to="/how-it-works/transparent"
            className={styles.tab}
            onMouseEnter={e => {
              if (e.target.children[0]) e.target.children[0].src = searchgreen;
            }}
            onMouseLeave={e => {
              if (e.target.children[0]) e.target.children[0].src = search;
            }}
          >
            <img src={search} alt="icon" />
            <div> Transparent </div>
          </Link>
          <Link
            to="/how-it-works/flexible"
            className={styles.tab}
            onMouseEnter={e => {
              if (e.target.children[0]) e.target.children[0].src = flexhover;
            }}
            onMouseLeave={e => {
              if (e.target.children[0]) e.target.children[0].src = flex;
            }}
          >
            <img src={flex} alt="icon" />
            <div> Flexible </div>
          </Link>
          <Link
            to="/how-it-works/control"
            className={styles.tab}
            onMouseEnter={e => {
              if (e.target.children[0]) e.target.children[0].src = controlgreen;
            }}
            onMouseLeave={e => {
              if (e.target.children[0]) e.target.children[0].src = control;
            }}
          >
            <img src={control} alt="icon" />
            <div> Control </div>
          </Link>
        </div>
      </div>
      {/* <div className={styles.hint}> More benefits for FirstHomes Africa users </div>
      <div className={styles.pad}>
        <ImgDesc
          title="Move from one property to another"
          image={side1}
          desc="Work transfers you to another city? Not to worry, just pack up your personal effects plus equity and settle into a similar FirstHomes Africa apartment in your new city (subject to availability- T&C Applies)"
        />
        <ImgDesc
          right
          title="Wealth creation"
          image={side2}
          desc="Not only do we provide housing solutions, we also help you with financial tips to improve your creditworthiness and set you on the path to financial freedom."
        />
        <ImgDesc
          title="Insurance cover for all our members"
          image={side3}
          desc="Sign up to a tailor-made insurance policy that covers your monthly payment for 6 months should you lose your job or unable to work due to personal injury. And in the unfortunate event of loss of life, the apartment is paid up in full and the ownership is transferred to your next of kin."
        />
        <ImgDesc
          right
          sub="Blockwise 101"
          title="Financial Literacy "
          image={side4}
          desc="Financial education is the key to wealth creation. A basic but sound knowledge of budgeting, interest rates, debts and equity will most certainly improve your investment decision."
        />
        <ImgDesc
          title="Community"
          image={side5}
          desc="We create communities of people with shared preferences and aspirations which promote collaborations, connections, knowledge sharing and support."
        />
      </div> */}
      <ScrollUpButton
        ToggledStyle={{ bottom: 100 }}
        style={{ borderColor: "#174a41" }}
      />
      <Footer />
      {modal && (
        <div className={styles.overall}>
          <div className={styles.card}>
            <video controls src={videoAd} />
            <button className={styles.linkbtn} onClick={() => setModal(false)}>
              EXIT
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default YourHome;
