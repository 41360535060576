import {
  ADD_CARD_SUCCESS,
  ADD_CARD_LOADING,
  ADD_CARD_FAILURE,
  ALL_CARD_SUCCESS,
  ALL_CARD_LOADING,
  ALL_CARD_FAILURE,
  ADD_CARD_OTP,
  ADD_CARD_PIN,
  ADD_CARD_URL,
  ADD_CARD_ADDRESS
} from "../actions/types/card";

const INITIAL_STATE = {
  type: null,
  loading: false,
  error: null,
  success: null,
  suggested_auth: null,
  authUrl: null,
  reference: null,
  flwref: null,
  redirectUrl: null,
  transaction_reference: null,
  cards: [],
  cards_loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_CARD_LOADING:
      return {
        ...state,
        loading: action.status
      };

    case ADD_CARD_FAILURE:
      return {
        ...state,
        loading: false
      };
    case ADD_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        type: null
      };
    case ALL_CARD_LOADING:
      return {
        ...state,
        cards_loading: true
      };

    case ALL_CARD_FAILURE:
      return {
        ...state,
        cards_loading: false
      };
    case ALL_CARD_SUCCESS:
      return {
        ...state,
        cards: action.cards,
        cards_loading: false
      };
    case ADD_CARD_OTP:
      return {
        ...state,
        loading: false,
        type: "otp",
        transaction_reference: action.transaction_reference
      };
    case ADD_CARD_PIN:
      return {
        ...state,
        loading: false,
        type: "pin",
        suggested_auth: action.suggested_auth
      };
    case ADD_CARD_URL:
      return {
        ...state,
        loading: false,
        authUrl: action.data.authUrl,
        flwref: action.data.flwref,
        reference: action.data.reference,
        type: "url"
      };
    case ADD_CARD_ADDRESS:
      return {
        ...state,
        loading: false,
        type: "billing",
        suggested_auth: action.suggested_auth
      };
    default:
      return state;
  }
};
