import Axios from "axios";
import { setNotify } from "./notify";

import {
  GET_HOUSE_TYPES,
  GET_STATES,
  GET_LOCATIONS,
  LOCATION_LOADING,
  CALCULATING,
  GET_CALCULATION,
  FETCH_CALCULATION,
  FETCH_DOWN_PAY,
  FETCH_DOWN_PAY_STATUS
} from "./types/onboard";
import { refreshUser } from "./auth";
import {
  getContribution,
  getContratual,
  getDeposits,
  getVoluntary
} from "./wallet";

export const getStateSuccess = states => ({
  type: GET_STATES,
  states
});

export const getHouseTypesSuccess = houses => ({
  type: GET_HOUSE_TYPES,
  houses
});
export const fetchDownSuccess = data => ({
  type: FETCH_DOWN_PAY,
  data
});
export const fetchDownStatusSuccess = data => ({
  type: FETCH_DOWN_PAY_STATUS,
  data
});

export const getLocationsSuccess = locations => ({
  type: GET_LOCATIONS,
  locations
});

export const getCalculationSuccess = calculation => ({
  type: GET_CALCULATION,
  calculation
});

export const fetchCalculationSuccess = calculation => ({
  type: FETCH_CALCULATION,
  calculation
});

export const locationLoading = () => ({
  type: LOCATION_LOADING
});

export const calculating = kind => ({
  type: CALCULATING,
  kind
});
export const getStates = () => dispatch => {
  return Axios.get("https://analytics.firsthomesafrica.com/api/admin/state")
    .then(async ({ data: { data } }) => {
      dispatch(getStateSuccess(data.states));
    })
    .catch(error => {
      return null;
    });
};

export const getHouseTypes = () => dispatch => {
  return Axios.get("https://analytics.firsthomesafrica.com/api/admin/platform")
    .then(async ({ data: { data } }) => {
      dispatch(getHouseTypesSuccess(data.houseDetails));
    })
    .catch(error => {
      return null;
    });
};

export const getLocations = state => dispatch => {
  dispatch(locationLoading());

  return Axios.get(
    `https://analytics.firsthomesafrica.com/api/admin/location?state=${state}`
  )
    .then(async ({ data: { data } }) => {
      dispatch(getLocationsSuccess(data.locations));
    })
    .catch(error => {
      return null;
    });
};

export const calculateHousing = (details, action) => dispatch => {
  dispatch(calculating(true));

  return Axios.post("/plan/mortgage", {
    ...details
  })
    .then(async ({ data: { data } }) => {
      dispatch(getDownPayementStatus());
      dispatch(generateDownPayement());
      dispatch(getCalculationSuccess(data));
      return action && action();
    })
    .catch(error => {
      dispatch(calculating(false));
      if (error.response) {
        return dispatch(
          setNotify({
            title: "Error",
            body: error.response.data.message,
            type: "error"
          })
        );
      }
    });
};

export const fetchCalculation = () => dispatch => {
  dispatch(calculating(true));

  return Axios.get("/plan/mortgage")
    .then(async ({ data: { data } }) => {
      dispatch(fetchCalculationSuccess(data));
    })
    .catch(error => {
       dispatch(calculating(false));
      return null;
    });
};

export const setPlanDate = (date, action) => dispatch => {
  dispatch(calculating(true));

  return Axios.post("/plan/setdate", {
    date
  })
    .then(async ({ data }) => {
      dispatch(
        setNotify({
          title: "Success",
          body: data.message,
          type: "success"
        })
      );
      dispatch(refreshUser());
      dispatch(fetchCalculation());
      dispatch(getContribution());
      dispatch(getContratual());
      dispatch(getVoluntary());
      dispatch(getDeposits());
      dispatch(generateDownPayement());
      dispatch(getDownPayementStatus());

      return action && action();
    })
    .catch(error => {
       dispatch(calculating(false));
      if (error.response) {
        return dispatch(
          setNotify({
            title: "Error",
            body: error.response.data.message,
            type: "error"
          })
        );
      }
    });
};
export const generateDownPayement = () => dispatch => {
  // dispatch(calculating(true));

  return Axios.get("/plan/make/downpayment")
    .then(async ({ data: { paymentDetails } }) => {
      dispatch(fetchDownSuccess(paymentDetails));
    })
    .catch(error => {
      //  dispatch(calculating(false));
      if (error.response) {
        return null;
      }
    });
};
export const getDownPayementStatus = () => dispatch => {
  // dispatch(calculating(true));

  return Axios.get("/plan/downpayment/status")
    .then(async ({ data: { data } }) => {
      dispatch(fetchDownStatusSuccess(data));
    })
    .catch(error => {
      //  dispatch(calculating(false));
      if (error.response) {
        return null;
      }
    });
};
