import Axios from "axios";

import { setNotify } from "./notify";
import {
  GET_CONTRIBUTUIONS,
  GET_DEPOSITS,
  GET_CONTRACTUAL,
  GET_WITHDRAWALS,
  GET_BANKS,
  VERIFYING_BANK,
  VERIFY_BANK,
  WITHDRAWING,
  GET_VOLUNTARY
} from "./types/wallet";

const getContributionSuccess = contributions => ({
  type: GET_CONTRIBUTUIONS,
  contributions
});

const getDepositSuccess = deposits => ({
  type: GET_DEPOSITS,
  deposits
});
const getContratualSuccess = contractual => ({
  type: GET_CONTRACTUAL,
  contractual
});

const getVoluntarySuccess = voluntary => ({
  type: GET_VOLUNTARY,
  voluntary
});
const getWithdrawalSuccess = withdrawals => ({
  type: GET_WITHDRAWALS,
  withdrawals
});

const getBankSuccess = banks => ({
  type: GET_BANKS,
  banks
});

const verifyBankSuccess = bank => ({
  type: VERIFY_BANK,
  bank
});

const verifyingBank = state => ({
  type: VERIFYING_BANK,
  state
});

const withdrawing = state => ({
  type: WITHDRAWING,
  state
});

export const getContribution = () => dispatch => {
  return Axios.get("/wallet/contributions")
    .then(async ({ data: { data } }) => {
      dispatch(getContributionSuccess(data));
    })
    .catch(error => {
      return null;
    });
};

export const getDeposits = () => dispatch => {
  return Axios.get("/wallet/deposits")
    .then(async ({ data: { data } }) => {
      dispatch(getDepositSuccess(data));
    })
    .catch(error => {
      return null;
    });
};

export const getContratual = () => dispatch => {
  return Axios.get("/wallet/contractual")
    .then(async ({ data: { data } }) => {
      dispatch(getContratualSuccess(data));
    })
    .catch(error => {
      return null;
    });
};

export const getVoluntary = () => dispatch => {
  return Axios.get("/wallet/contractual?voluntary=voluntary")
    .then(async ({ data: { data } }) => {
      dispatch(getVoluntarySuccess(data));
    })
    .catch(error => {
      return null;
    });
};
export const getBanks = () => dispatch => {
  return Axios.get("/banks/list ")
    .then(async ({ data }) => {
      dispatch(getBankSuccess(data));
    })
    .catch(error => {
      return null;
    });
};

export const verifyBank = details => dispatch => {
  dispatch(verifyingBank(true));
  return Axios.post("/banks/verify", {
    ...details
  })
    .then(async ({ data: { data: { data } } }) => {
      if (!data.accountname) {
        dispatch(verifyingBank(false));
        return dispatch(
          setNotify({
            type: "error",
            title: "Account not Verified",
            body: data.responsemessage
          })
        );
      }
      dispatch(verifyBankSuccess(data));
    })
    .catch(error => {
      dispatch(verifyingBank(false));

      return null;
    });
};

export const getWithdrawal = () => dispatch => {
  return Axios.get("/withdrawal")
    .then(async ({ data: { data } }) => {
      dispatch(getWithdrawalSuccess(data));
    })
    .catch(error => {
      return null;
    });
};

export const setWithdrawal = details => dispatch => {
  dispatch(withdrawing(false));
  return Axios.post("/withdrawal", {
    ...details
  })
    .then(async ({ data: { data } }) => {
      dispatch(withdrawing(false));
      dispatch(getWithdrawal());
      return dispatch(
        setNotify({
          type: "success",
          title: "Withdrawal sucessfull",
          body: data.message
        })
      );
    })
    .catch(error => {
      dispatch(withdrawing(false));
      if (error.response)
        return dispatch(
          setNotify({
            type: "error",
            title: "Withdrawal not sucessfull",
            body: error.response.data.message
          })
        );
    });
};
