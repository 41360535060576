import styles from "./index.module.scss";
import React, { useState } from "react";

const DropDown = ({ title, data }) => {
  const [show, setShow] = useState(false);
  const [current, setCurrent] = useState(`${data[0].name}`);

  return (
    <div className={styles.dropdown} onMouseLeave={() => setShow(false)}>
      <div className={styles.title} onClick={() => setShow(true)}>
        {current} <i className="lni-chevron-down" />
      </div>
      {show && (
        <div className={styles.wrap}>
          <div className={styles.items}>
            {data.map((datum, i) => (
              <div
                key={i}
                onClick={() => {
                  setCurrent(datum.name);
                  datum.action();
                  return setShow(false);
                }}
              >
                {datum.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDown;
