import {
  GET_CONTRACTUAL,
  GET_CONTRIBUTUIONS,
  GET_DEPOSITS,
  GET_WITHDRAWALS,
  GET_BANKS,
  VERIFYING_BANK,
  VERIFY_BANK,
  WITHDRAWING,
  GET_VOLUNTARY
} from "../actions/types/wallet";
const INITIAL_STATE = {
  deposits: [],
  contributions: {},
  contractual: {},
  withdrawals: [],
  banks: [],
  verifying: false,
  verified: null,
  withdrawing: false,
  voluntary: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CONTRACTUAL:
      return {
        ...state,
        contractual: action.contractual
      };
    case GET_VOLUNTARY:
      return {
        ...state,
        voluntary: action.voluntary
      };
    case GET_CONTRIBUTUIONS:
      return {
        ...state,
        contributions: action.contributions
      };

    case GET_DEPOSITS:
      return {
        ...state,
        deposits: action.deposits
      };
    case GET_WITHDRAWALS:
      return {
        ...state,
        withdrawals: action.withdrawals
      };
    case GET_BANKS:
      return {
        ...state,
        banks: action.banks
      };

    case VERIFYING_BANK:
      return {
        ...state,
        verifying: action.state
      };
    case WITHDRAWING:
      return {
        ...state,
        withdrawing: action.state
      };

    case VERIFY_BANK:
      return {
        ...state,
        verifying: false,
        verified: action.bank
      };

    default:
      return state;
  }
};
