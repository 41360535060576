import React from "react";
import article2 from "../../../assets/article2.webp";
import styles from "./index.module.scss";

const BlockWise = () => {
  return (
    <div className={styles.save}>
      <div className={styles.head}>
        <div className={styles.title}>BlockWise</div>
        <div className={styles.sub}>
          Empowering individuals with personal financial & wealth management
          tools
        </div>
      </div>
      <div className={styles.new}>
        <div className={styles.title}>WHAT’S NEW</div>

        <div className={styles.cards}>
          <div className={styles.card}>
            <div className={styles.img}>
              <img src={article2} alt="" />
            </div>
            <div className={styles.header}>
              <div className={styles.title}>
                Learning to control your spending habits 101.
              </div>
              <div className={styles.time}>3 min. read</div>
            </div>
            <div className={styles.desc}>
              Lorem ipsum dolor sit aet consecutur adspicing elit bon biz lagen
              devit carregar cargando
            </div>
          </div>
        </div>
      </div>
      <div className={styles.new}>
        <div className={styles.title}>LAST WEEK</div>

        <div className={styles.cards}>
          <div className={styles.card}>
            <div className={styles.img}>
              <img src={article2} alt="" />
            </div>
            <div className={styles.header}>
              <div className={styles.title}>
                Learning to control your spending habits 101.
              </div>
              <div className={styles.time}>3 min. read</div>
            </div>
            <div className={styles.desc}>
              Lorem ipsum dolor sit aet consecutur adspicing elit bon biz lagen
              devit carregar cargando
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.img}>
              <img src={article2} alt="" />
            </div>
            <div className={styles.header}>
              <div className={styles.title}>
                Learning to control your spending habits 101.
              </div>
              <div className={styles.time}>3 min. read</div>
            </div>
            <div className={styles.desc}>
              Lorem ipsum dolor sit aet consecutur adspicing elit bon biz lagen
              devit carregar cargando
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.img}>
              <img src={article2} alt="" />
            </div>
            <div className={styles.header}>
              <div className={styles.title}>
                Learning to control your spending habits 101.
              </div>
              <div className={styles.time}>3 min. read</div>
            </div>
            <div className={styles.desc}>
              Lorem ipsum dolor sit aet consecutur adspicing elit bon biz lagen
              devit carregar cargando
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.img}>
              <img src={article2} alt="" />
            </div>
            <div className={styles.header}>
              <div className={styles.title}>
                Learning to control your spending habits 101.
              </div>
              <div className={styles.time}>3 min. read</div>
            </div>
            <div className={styles.desc}>
              Lorem ipsum dolor sit aet consecutur adspicing elit bon biz lagen
              devit carregar cargando
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.img}>
              <img src={article2} alt="" />
            </div>
            <div className={styles.header}>
              <div className={styles.title}>
                Learning to control your spending habits 101.
              </div>
              <div className={styles.time}>3 min. read</div>
            </div>
            <div className={styles.desc}>
              Lorem ipsum dolor sit aet consecutur adspicing elit bon biz lagen
              devit carregar cargando
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.img}>
              <img src={article2} alt="" />
            </div>
            <div className={styles.header}>
              <div className={styles.title}>
                Learning to control your spending habits 101.
              </div>
              <div className={styles.time}>3 min. read</div>
            </div>
            <div className={styles.desc}>
              Lorem ipsum dolor sit aet consecutur adspicing elit bon biz lagen
              devit carregar cargando
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlockWise;
