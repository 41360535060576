import React, { useState } from "react";
import styles from "./index.module.scss";
import playBtn from "../../assets/play-green.svg";
import pauseBtn from "../../assets/pause.svg";

const FullBannerVideo = ({ title, image, content, poster }) => {
  const [icons, setIcons] = useState({
    playCenter: playBtn,
  });
  const playPause = e => {
    e && e.preventDefault();
    const video = document.getElementById("video");
    if (video.paused) {
      video.play();
      setIcons({
        ...icons,
        playCenter: pauseBtn
      });
    } else {
      video.pause();
      setIcons({
        ...icons,
        playCenter: playBtn
      });
    }
  };

  return (
    <div className={styles.fullBanner}>
      <video id="video" src={content} poster={poster} />
      <div
        className={`${styles.control} ${
          icons.playCenter === pauseBtn ? styles.pau : ""
        }`}
      >
        <img
          onClick={playPause}
          src={icons.playCenter}
          alt="playCenter"
          id="playCenter"
        />
      </div>
    </div>
  );
};

export default FullBannerVideo;
