import React, { useState } from 'react';

import Folder from '../../components/Folder';
import Footer from '../../components/Footer';
import Nav from '../../components/Nav';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import Whatsapp from '../../components/Whatsapp';
import banner from '../../assets/FAQ.png';
import styles from './index.module.scss';

const FAQs = () => {
  const [current, setCurrent] = useState('general');
  const details = {
    general: {
      items: [
        {
          title: 'What is FirstHomes Africa?',
          body:
            'Established in 2014, FirstHomes Africa is an online real estate company that focuses on the least served yet largest demographic in the African Housing Market. With the average age of the continent at 19.4 years and the urban population estimated at 400 million by 2050, the future is in the youth of Africa'
        },
        {
          title: 'What is the regular scheme?',
          body:
            'Under the regular scheme, pre-qualification for a lease to own agreement is subject to subscribers saving 15% of the property value in 15 consecutive monthly installments (No down payments required)'
        },
        {
          title: 'What is the accelerated scheme?',
          body:
            'This scheme is for those with available funds who will like to move into their apartments in less than a year. Under this scheme, with a 20% initial down payment, you can pre-qualify for a lease to own agreement in 9 months.  If you do not have 20% down payment and you want to accelerate, you still can. With a 10% down payment you pre-qualify within 13 months and with a 15% down payment within 11 months'
        },
        {
          title: 'Will the program be available to Nigerians in the Diaspora?',
          body: 'Yes'
        },
        {
          title: 'Does FirstHomes Africa operate in the 36 States of the Federation?',
          body:
            'Yes, FirstHomes Africa aims to offer housing solutions in all 36 states of the federation. The actual location of the build will depend on the demand amongst other factors in that area'
        },
        {
          title:
            'Does my preference mean that a property will be built in the area of my choice?',
          body:
            'Your preference allows FirstHomes Africa to aggregate the total demand for housing in a specific area. The total demand in an area amongst other factors will determine the actual location where the property will built. If at the time of initiating your option to enter a lease to own agreement the location of the build is not suitable for you, you may elect to either remain on the scheme until your preferred location becomes available or exit the scheme altogether'
        },
        {
          title:
            'What other factors will FirstHomes Africa consider in deciding a location?',
          body:
            'Population, Demography, Income, Education, Transportation, Availability of Public Facilities, Crime Rate/Trend, Government Regulations & Restrictions, Competing Projects (current & ongoing), Home Ownership Culture & Employment Trends'
        },
        {
          title: 'If I miss a month payment, will I be disqualified?',
          body:
            'No. However, your estimated move in date will be extended and your interest rate could be affected'
        },
        {
          title: 'Can I change my mind at anytime?',
          body: 'Yes'
        },
        {
          title: 'Can I change my preference in the middle of the program?',
          body:
            'Yes; you can make 1 change (in addition to your initial selection) within 30 days of sign-up'
        },
        {
          title: 'Can I change my budget in the middle of the program?',
          body:
            'Yes; subject to the minimum allowable contribution of N50,000 monthly'
        },
        {
          title: 'What is contractual equity savings?',
          body:
            'This is 15% of the cost of proposed apartment payable over a 15 month period'
        },
        {
          title: 'What is voluntary equity savings?',
          body:
            'This is a non contractual savings by the subscriber made within the 15 month period which enables you set aside additional funds when you have windfall. This has a positive impact on your credit worthiness'
        },
        {
          title: 'How many months must I save to be qualified?',
          body:
            '15 months on the regular scheme ( zero down payments) and minimum 9 months on the accelerated scheme (actual number of months vary depending on percentage down payment)'
        },
        {
          title: 'What is the maximum lease to own duration?',
          body: '15 years'
        },
        // {
        //   title: "Can I accelerate the process by making a downpayment?",
        //   body:
        //     "Yes;  however the down payment is non-refundable and you will still be required to save over a minimum period of 9 months to pre-qualify. The actual number of months savings vary depending on the percentage down payment "
        // },
        {
          title:
            'Can I change my location in the middle of the program from one city to another?',
          body:
            'Yes - the scheme enables you make up to 1 change (in addition to your initial selection) during the pre-qualification phase'
        },
        {
          title: 'What does Qualification "due diligence" checks entail?',
          body:
            'At the time of signing up to the scheme, FirstHomes Africa does not verify your earning capacity or your income status. All we are interested in at this time is your proven ability to meet your monthly commitments. However before we sign a "lease to own contract" with you, we will carry out a full financial check to ensure that you can afford the monthly repayments over an extended period'
        },
        {
          title: 'What happens if I do not pass the due diligence checks?',
          body:
            'Your application for a lease to buy agreement will be rejected and your savings returned to you'
        },
        {
          title: 'What happens if I cannot meet my obligations to FirstHomes Africa?',
          body:
            "Before approval (see eligibility page), there's no obligation to the subscriber other than as stated in the Terms & Conditions. Once you move in, the terms stated in the lease to own contract applies. However FirstHomes Africa allows you exit the scheme post contract"
        },
        {
          title: 'What if I am self employed?',
          body:
            'You can still join the scheme as it is open to those with a proven regular source of income'
        },
        {
          title: 'Can I enroll for more than one at a time?',
          body:
            'No; you are only entitled to one apartment on the scheme. However, you can upscale or downsize whilst on the scheme'
        },
        {
          title: 'Can I let out my apartment?',
          body:
            'No; the terms of the lease to buy agreement is for own use only'
        },
        {
          title: 'Will the apartment be furnished?',
          body: 'No, but it will be move in ready and not a carcass'
        },
        {
          title: 'Does FirstHomes Africa have a Mobile App?',
          body: 'Yes; available on android and ios'
        },
        {
          title:
            'Upon qualification, can I use the platform funds to acquire properties outside the network?',
          body:
            'No; the savings made on the FirstHomes Africa platform is towards purchasing a FirstHomes Africa property'
        },
        {
          title: 'Who is eligible for this program?',
          body:
            'Minimum of 18 years, gainfully employed  (self or institutional) and have sufficient income with a good financial history and/or a healthy monthly cashflow'
        },
        {
          title: 'Is there an opportunity for joint ownership?',
          body: 'Yes, but we recognize only legally bound parties'
        },
        {
          title: 'Can I sell my apartment?',
          body:
            'Yes, but you can only sell the apartment within FirstHomes Africa Ecosystem'
        },
        {
          title: 'Can I move to a bigger apartment within the ecosystem?',
          body:
            'Yes; an upscale will be considered a new contract with FirstHomes Africa allowing you carry over your equity from your previous apartment as a down payment'
        },
        {
          title:
            'Can I scale down to a smaller apartment within the ecosystem?',
          body:
            'Yes; a downsize will be considered a new contract with FirstHomes Africa allowing you carry over your equity from your previous apartment as a down payment'
        },
        {
          title: 'Can I transfer ownership of my obligation?',
          body:
            'No - the contract with FirstHomes Africa is non-transferrable. You can however exit the scheme and sell the apartment back to FirstHomes Africa'
        },
        {
          title: 'Do you have customer care numbers?',
          body: '07008000500'
        },
        {
          title: 'Do you have professional real estate advisors?',
          body: 'Yes'
        },
        {
          title: 'What is FirstHomes Africa community?',
          body:
            'A cluster of our subscribers sharing the same preferences within the same geographical location'
        },
        {
          title: 'What are the benefits of the FirstHomes Africa community?',
          body:
            'Symposiums, learning, financial literacy, periodic meetings and networking'
        },
        {
          title: 'What is the maximum property value for FirstHomes Africa?',
          body: 'Not more than N50m'
        },
        {
          title: 'What is the maximum age for eligibility?',
          body: 'Not more than 45 years old'
        },
        {
          title: 'What benefits of the FirstHomes Africa VIP status?',
          body:
            'The FirstHomes Africa black card allows you priority and preferences at our partner bars, clubs, restaurants, travel agents and stores.'
        },
        {
          title: 'How do I attain the FirstHomes Africa VIP status?',
          body: 'By signing up to the accelerate scheme.'
        },
        {
          title:
            'Who are the FirstHomes Africa partners under the VIP/accelerate scheme?',
          body: 'A full list will be uploaded soon'
        },
        {
          title: 'Can I personalize my apartment design and layout?',
          body:
            'Our apartments come in standard layouts this is how we can cut on costs and provide affordable housing'
        },
        {
          title:
            'What happens to my money if the flat is not available by the move in date?',
          body:
            'You have no risk, you can withdraw your contribution in full without any penalties'
        },
        {
          title: 'What if I do not like the quality of the apartments?',
          body:
            'You have no risk, you can withdraw your contribution in full without any penalties'
        },
        {
          title:
            'How can I be sure that I will be living with like minded people?',
          body:
            'This scheme is designed to attract like minded people. In addition, FirstHomes Africa Community  "meet up" allows our members opportunity to network and socialize ahead of the move-in date'
        },
        {
          title: 'How can I downsize, upgrade or move across geographies?',
          body:
            'You in essence move the value of your equity across, above and below and enter into a new lease to own agreement for the new apartment'
        },
        {
          title: 'Do you have agents?',
          body: 'No! Our products are self-service with no middle men'
        }
      ]
    },
    finance: {
      items: [
        {
          title: 'Can I withdraw the bonus earnings?',
          body:
            'No. This bonus has been provided only to enable you reach your target goal quicker'
        },
        {
          title: 'What are the charges for withdrawal?',
          body:
            'For up to 5% of your monthly contribution; there is no charge. 10% for delayed withdrawal (you wait for 7 days to receive the funds) & 15% for instant (you receive the funds instantly) withdrawal'
        },
        {
          title:
            'Can I withdraw my initial down payment if I am on the accelerate scheme?',
          body:
            'Yes you can, with a 90 days notice of intention to withdraw and subject to a 10% charge'
        },
        {
          title: 'Do I earn interest on my savings?',
          body:
            'No, we will however incentivize you with a welcome bonus which is higher than what you would have earned on your savings in a bank'
        },
        {
          title: 'What is the maximum loan I can access on the platform?',
          body: 'Your average monthly savings multiplied by 100'
        },
        {
          title:
            'What is the minimum down payment to pre-quality for a lease to own agreement?',
          body: '15% of the budgeted apartment price'
        },
        // {
        //   title: "What is the minimum down payment?",
        //   body: "10% of the budgeted apartment price"
        // },
        {
          title: 'Can I have access to my deposit while I am still saving?',
          body: 'Yes'
        },
        {
          title: 'Can I discontinue this program at any time?',
          body: 'Yes'
        },
        {
          title: 'Are there financial  penalties for late payment?',
          body: 'No, but it will affect your credit score'
        },
        {
          title:
            'Are there financial penalties for omission of payment for a particular month?',
          body:
            'No, but pre-qualification date will be extended by 2 months for every omission of payment and it impacts your interest rate upon approval'
        },
        {
          title: 'How do I secure the loan?',
          body: 'Your apartment is your collateral'
        },
        {
          title:
            'Can I add more than one account in paying my monthly equity contribution?',
          body: 'Yes'
        },
        {
          title: 'How do I make payment on the platform?',
          body:
            'Through the website or mobile app. (Disclaimer: No payment is made through any agent or any other platform except the FirstHomes Africa Platform)'
        },
        {
          title: 'Can I spend money directly from my account?',
          body:
            'No, you can however withdraw from the vault and transfer to the source account (bank account) and spend from there'
        },
        {
          title: 'Is FirstHomes Africa a Bank or a Financial Institution?',
          body:
            'No, we are a real estate technology platform that provides our customers, housing solutions with extended payment periods'
        },
        {
          title:
            'How will I know the equity I have in the apartment after moving in?',
          body:
            'Based on market value of the property vs the amounts you have paid, excluding interest payments'
        },
        {
          title: 'Upon approval, can I withdraw funds from the platform?',
          body:
            'No; once you have initiated your option to enter a lease to own agreement you can no longer withdraw funds from the platform, However, once you receive your keys, you can sell your property back to FirstHomes Africa'
        },
        {
          title: 'When will my monthly payment be due?',
          body:
            'At the beginning of the program, you will set a payment date by yourself'
        },
        {
          title: 'Can I change the payment due date?',
          body: 'Yes'
        },
        {
          title: 'How do you disburse the funds?',
          body:
            'Funds are not disbursed to individuals, but we pay for the apartments on your behalf'
        },
        {
          title:
            'Can my parents or any other 3rd party assist in paying my obligation?',
          body: 'Yes'
        },
        {
          title: 'Is there any reward for consistent payments?',
          body: 'Yes, discounted interest rates from the advertised amounts'
        }
      ]
    },
    insurance: {
      items: [
        {
          title:
            'What kind of insurance scheme covers me during the pre-approval phase?',
          body:
            'With a 6 months subscription, you qualify for free health insurance which covers for major illnesses for 1 year (stroke, heart attack & cancer), Terms & Conditions will apply'
        },
        {
          title: 'What kind of insurance scheme covers me after approval?',
          body:
            'A policy that pays your rent for 6 months should you lose your job'
        },
        {
          title:
            'Who are those eligible for the FirstHomes Africa loss of job insurance?',
          body:
            'You must be a member of the FirstHomes Africa community, your loan must be performing and you must comply with the policy terms'
        },
        {
          title: 'In the case of disability or death what happens?',
          body:
            'If your loan is performing, the insurance will cover your obligations as per policy'
        }
      ]
    },
    maintenance: {
      items: [
        {
          title: 'How will the property be managed?',
          body:
            'By a management company and each apartment block will have an onsite facility manager'
        },
        {
          title: 'Will there be a service fee?',
          body:
            'Yes  - for the management of the communal areas. All apartments will have meters'
        },
        {
          title:
            'Who shall be responsible for the maintenance of the apartment?',
          body:
            'The apartments come with 3 years warranty, that means in the first 3 years, FirstHomes Africa will be responsible for all repairs, thereafter the subscriber takes responsibility'
        }
      ]
    },
    security: {
      items: [
        {
          title: 'Is my information safe with FirstHomes Africa?',
          body:
            'Yes  - your information is stored in a secure cloud server powered by SSQL'
        }
      ]
    },
    referral: {
      items: [
        {
          title: 'How does the welcome bonus work?',
          body:
            'The welcome bonus is a one off payment made to every new subscriber to the platform. The welcome bonus is 100% of the initial monthly payment made up to a maximum of N500,000'
        },
        {
          title: 'How does the referral scheme work?',
          body:
            'The referral scheme enables you increase your equity contribution by referring friends and family to the scheme. For every successful referral made, the referrer gets a N5000 equity contribution '
        },
        {
          title: 'How long will the bonus program run for?',
          body: '3 months'
        },
        {
          title: 'How many referrals can I make?',
          body:
            'As many as you can, provided they are within your network of friends and family and they meet the eligibility criteria'
        },
        {
          title:
            'What is the maximum amount of welcome bonus I can earn on the platform?',
          body: 'N500,000 one time welcome bonus'
        },
        {
          title: 'How much can I earn on referral bonus?',
          body:
            'You can earn multiples of N5,000 on referees that have completed their sign up process using your referral code'
        },
        {
          title: 'Who can I refer to FirstHomes Africa referral program?',
          body: 'People within your network'
        },
        {
          title: 'What is a successful referral?',
          body:
            'The referee signs up to the FirstHomes Africa scheme using the referral code during the sign-up process'
        },
        {
          title: 'When do I get my referral reward?',
          body: 'Maximum of 7 days from date of account funding by the referee'
        },
        {
          title: 'When do I get my welcome bonus?',
          body: 'Immediately you sign up'
        },
        {
          title:
            "Do I get a welcome bonus if I don't use a referral code to sign up?",
          body: 'Yes'
        }
      ]
    }
  };
  return (
    <div className={styles.FAQs}>
      <Whatsapp />
      <Nav showBackground address />
      <div className={styles.main}>
        <div
          className={styles.back}
          style={{
            background: `url(${banner}) bottom center`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          FAQs
        </div>
        <div className={styles.header}>
          <div className={styles.tabs}>
            <button
              className={current === 'general' ? styles.active : ''}
              onClick={() => setCurrent('general')}
            >
              General
            </button>
            <button
              className={current === 'finance' ? styles.active : ''}
              onClick={() => setCurrent('finance')}
            >
              Finance
            </button>
            <button
              className={current === 'insurance' ? styles.active : ''}
              onClick={() => setCurrent('insurance')}
            >
              Insurance
            </button>
            <button
              className={current === 'maintenance' ? styles.active : ''}
              onClick={() => setCurrent('maintenance')}
            >
              Maintenance
            </button>
            <button
              className={current === 'security' ? styles.active : ''}
              onClick={() => setCurrent('security')}
            >
              Security
            </button>
            <button
              className={current === 'referral' ? styles.active : ''}
              onClick={() => setCurrent('referral')}
            >
              Referral
            </button>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.items}>
            {details[current].items.map((item, i) => (
              <div>
                <Folder key={i} title={item.title} body={item.body} green big />
              </div>
            ))}
          </div>
        </div>
      </div>
      <ScrollUpButton
        ToggledStyle={{ bottom: 100, zIndex: 999 }}
        style={{ borderColor: '#174a41' }}
      />
      {/* <button onClick={() => window.scrollTo(0, 0)}>BACK TO TOP</button> */}
      <Footer />
    </div>
  );
};

export default FAQs;
