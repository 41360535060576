import styles from "./index.module.scss";
import React, { useState, useEffect } from "react";
import spinner from "../../../../assets/spinner.svg";
import { useDispatch, useMappedState } from "redux-react-hook";
import { uploadImage, setNOK } from "../../../../store/actions/auth";
import includesAll from "../../../../utils/includesAll";
import { setNotify } from "../../../../store/actions/notify";

const Profile = () => {
  const dispatch = useDispatch();
  const { currentUser: user, loading, nok: kins } = useMappedState(
    ({ auth }) => auth
  );
  const plan = useMappedState(({ onboard }) => onboard.calculation);
  const contributions = useMappedState(({ wallet }) => wallet.contributions);

  const [edit, setedit] = useState(false);
  const [details, setdetails] = useState({});

  useEffect(() => {
    if (kins.nextOfKinName) {
      setdetails({
        name: kins.nextOfKinName,
        relationship: kins.nextOfKinRelationship,
        email: kins.nextOfKinEmail,
        phoneNumber: kins.nextOfKinPhone
      });
    }
  }, [kins]);

  const onchange = e => {
    if (e.target.value === "") {
      delete details[e.target.name];
    }
    setdetails({
      ...details,
      [e.target.name]: e.target.value
    });
  };
  const upload = e => {
    return dispatch(uploadImage(e.target.files[0]));
  };
  return (
    <div className={styles.profile}>
      <div className={styles.main}>
        <div className={styles.top}>
          <div className={styles.image}>
            <div className={styles.img}>
              <img src={user.profilePicture && user.profilePicture} alt="" />
              {loading && (
                <img src={spinner} alt="" className={styles.loading} />
              )}
            </div>
            <input type="file" onChange={upload} />
          </div>
          <div className={styles.side}>
            <div className={styles.row}>
              <div className={styles.grp}>
                <div className={styles.lft}>First Name</div>
                <div className={styles.rgt}>{user && user.firstname}</div>
              </div>

              <div className={styles.grp}>
                <div className={styles.lft}>Points Score</div>
                <div className={styles.rgt__b}>
                  {contributions.morgagePoints}
                </div>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.grp}>
                <div className={styles.lft}>Last Name</div>
                <div className={styles.rgt}>{user && user.lastname}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.head}>D.O.B</div>
          <div className={styles.item}>
            {user && new Date(user.dateOfBirth).toDateString()}
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>Email</div>
          <div className={styles.item}>{user && user.email}</div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>Phone</div>
          <div className={styles.item}>{user && user.phoneNumber}</div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>Housing type</div>
          <div className={styles.item}>{plan && plan.houseType}</div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>Location</div>
          <div className={styles.item}>{plan && plan.location}</div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>Estimated Maturity Date</div>
          <div className={styles.item}>{plan && plan.maturityDate}</div>
        </div>
      </div>
      <div className={styles.others}>
        <div className={styles.list}>
          <div className={styles.title}>Next of Kin Details</div>
          <div>
            <button
              className={edit ? styles.edit : styles.btn}
              onClick={
                edit
                  ? () => {
                      if (
                        !includesAll(Object.keys(details), [
                          "name",
                          "email",
                          "relationship",
                          "phoneNumber"
                        ])
                      ) {
                        return dispatch(
                          setNotify({
                            type: "error",
                            title: "All fields required",
                            body: "Insert all fields correctly"
                          })
                        );
                      }

                      if (
                        !/^[a-zA-Z-]+ ?[a-zA-Z]+$/.test(details.name.trim())
                      ) {
                        return dispatch(
                          setNotify({
                            type: "error",
                            title: "Name error",
                            body: "Name must not contain a number"
                          })
                        );
                      }
                      if (
                        !/^\+\d{3}\d{10}$|^\d{11}$/.test(details.phoneNumber)
                      ) {
                        return dispatch(
                          setNotify({
                            type: "error",
                            title: "Phone Number error",
                            body: "Not a valid number"
                          })
                        );
                      }
                      if (
                        !/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                          details.email.trim()
                        )
                      ) {
                        return dispatch(
                          setNotify({
                            type: "error",
                            title: "Email error",
                            body: "Not a valid email"
                          })
                        );
                      }
                      if (
                        !/^father$|^mother$|^brother$|^sister$|^aunt$|^cousin$|^nephew$|^niece$|^uncle$|^wife$|^husband$|^child$|^fiancee$/i.test(
                          details.relationship.trim()
                        )
                      ) {
                        return dispatch(
                          setNotify({
                            type: "error",
                            title: "Relationship error",
                            body:
                              "Relationship must be one of these: father, mother, brother, sister, aunt, cousin, nephew, niece, uncle, wife, husband, child, fiancee"
                          })
                        );
                      }
                      dispatch(setNOK(details, () => setedit(false)));
                    }
                  : () => setedit(true)
              }
            >
              {edit ? "Save" : <i className="lni lni-pencil" />}
            </button>
            {edit && (
              <button onClick={() => setedit(false)} className={styles.edit}>
                cancel
              </button>
            )}
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.head}>Name</div>
          <div className={styles.item}>
            {edit ? (
              <input
                name="name"
                value={details.name}
                type="text"
                onChange={onchange}
              />
            ) : kins.nextOfKinName ? (
              `${kins.nextOfKinName}`
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.head}>Relationship</div>
          <div className={styles.item}>
            {edit ? (
              <input
                name="relationship"
                value={details.relationship}
                type="text"
                onChange={onchange}
              />
            ) : kins.nextOfKinRelationship ? (
              `${kins.nextOfKinRelationship}`
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.head}>Phone Number</div>
          <div className={styles.item}>
            {edit ? (
              <input
                name="phoneNumber"
                value={details.phoneNumber}
                type="tel"
                onChange={onchange}
              />
            ) : kins.nextOfKinPhone ? (
              `${kins.nextOfKinPhone}`
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.head}>Email Address</div>
          <div className={styles.item}>
            {edit ? (
              <input
                name="email"
                value={details.email}
                type="email"
                onChange={onchange}
              />
            ) : kins.nextOfKinEmail ? (
              `${kins.nextOfKinEmail}`
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
