import Axios from 'axios';
import {
  AUTH_USER_REQUEST,
  AUTH_USER_ERROR,
  AUTH_USER_SUCCESS,
  LOGOUT_USER,
  UPLOAD_REQUEST,
  UPLOAD_SUCCESS,
  ADD_NOK
} from './types/users';
import { setNotify } from './notify';
import {
  getStates,
  getHouseTypes,
  fetchCalculation,
  generateDownPayement,
  getDownPayementStatus
} from './onboard';
import {
  getContratual,
  getDeposits,
  getWithdrawal,
  getBanks,
  getContribution,
  getVoluntary
} from './wallet';
import { allCards } from './card';
const token = `0911f88dd190789f16ee1ec43d0340de360f4895ed2d4204091bb63bf3c1bd95da7161d4b689`;

const authUserRequest = state => ({
  type: AUTH_USER_REQUEST,
  state
});
export const uploadRequest = state => ({
  type: UPLOAD_REQUEST,
  state
});

const authUserError = error => ({
  type: AUTH_USER_ERROR,
  error
});

export const authUserSuccess = user => ({
  type: AUTH_USER_SUCCESS,
  user
});

export const uploadSuccess = files => ({
  type: UPLOAD_SUCCESS,
  files
});

export const addNok = nok => ({
  type: ADD_NOK,
  nok
});

const logoutUserSuccess = () => ({
  type: LOGOUT_USER
});

export const loginUser = (details, history) => dispatch => {
  dispatch(authUserRequest(true));
  return Axios.post('/login', {
    ...details
  })
    .then(async ({ data: { data } }) => {
      Axios.defaults.headers.common['x-access-token'] = data.token;
      localStorage.setItem(
        'user',
        JSON.stringify({
          ...data.user,
          token: data.token
        })
      );
      localStorage.setItem('token', data.token);
      // dispatch(
      //   authUserSuccess({
      //     ...data.user,
      //     token: data.token
      //   })
      // );
      await dispatch(refreshUser());
      dispatch(getNOK());
      dispatch(fetchCalculation());
      dispatch(getStates());
      dispatch(getHouseTypes());
      dispatch(getContratual());
      dispatch(getVoluntary());
      dispatch(getContribution());
      dispatch(getDeposits());
      dispatch(allCards());
      dispatch(getFiles());
      dispatch(getWithdrawal());
      dispatch(getBanks());
      dispatch(generateDownPayement());
      dispatch(getDownPayementStatus());
      dispatch(
        setNotify({
          title: 'Success',
          body: 'Logged in successfully',
          type: 'success'
        })
      );
      return history && history.push('/dashboard');
    })
    .catch(error => {
      dispatch(authUserRequest(false));
      if (error.response) {
        dispatch(authUserError(error.response.data));
        return dispatch(
          setNotify({
            title: 'Error',
            body: error.response.data.message,
            type: 'error'
          })
        );
      }
    });
};

export const sendOtp = (details, action, norefresh) => dispatch => {
  dispatch(authUserRequest(true));
  return Axios.post('/otp/validate', {
    ...details
  })
    .then(async ({ data }) => {
      dispatch(authUserRequest(false));
      dispatch(
        setNotify({
          title: 'Success',
          body: data.message,
          type: 'success'
        })
      );
      !norefresh && dispatch(refreshUser());
      return action && action();
    })
    .catch(error => {
      dispatch(authUserRequest(false));
      if (error.response) {
        dispatch(authUserError(error.response.data));
        return dispatch(
          setNotify({
            title: 'Error',
            body: error.response.data.message,
            type: 'error'
          })
        );
      }
    });
};

export const setPassword = (passwords, action, norefresh) => dispatch => {
  dispatch(authUserRequest(true));
  return Axios.post('/password', {
    ...passwords
  })
    .then(async ({ data }) => {
      dispatch(authUserRequest(false));
      dispatch(
        setNotify({
          title: 'Success',
          body: data.message,
          type: 'success'
        })
      );
      !norefresh && dispatch(refreshUser());
      return action && action();
    })
    .catch(error => {
      dispatch(authUserRequest(false));
      if (error.response) {
        dispatch(authUserError(error.response.data));
        return dispatch(
          setNotify({
            title: 'Error',
            body: error.response.data.message,
            type: 'error'
          })
        );
      }
    });
};

export const reSendOtp = () => dispatch => {
  dispatch(authUserRequest(true));
  return Axios.get('/otp/resend')
    .then(async ({ data }) => {
      dispatch(authUserRequest(false));
      dispatch(
        setNotify({
          title: 'Success',
          body: data.message,
          type: 'success'
        })
      );
    })
    .catch(error => {
      dispatch(authUserRequest(false));
      if (error.response) {
        dispatch(authUserError(error.response.data));
        return dispatch(
          setNotify({
            title: 'Error',
            body: error.response.data.message,
            type: 'error'
          })
        );
      }
    });
};
export const forgotPassword = (details, action) => dispatch => {
  dispatch(authUserRequest(true));
  return Axios.post('/forgot', {
    ...details
  })
    .then(async ({ data }) => {
      dispatch(authUserRequest(false));
      Axios.defaults.headers.common['x-access-token'] = data.token;
      action && action();
      dispatch(
        setNotify({
          title: 'Success',
          body: data.message,
          type: 'success'
        })
      );
    })
    .catch(error => {
      dispatch(authUserRequest(false));
      if (error.response) {
        dispatch(authUserError(error.response.data));
        return dispatch(
          setNotify({
            title: 'Error',
            body: error.response.data.message,
            type: 'error'
          })
        );
      }
    });
};

export const sendReferrals = emails => dispatch => {
  return Axios.post('/referral', {
    emails
  })
    .then(async ({ data }) => {
      dispatch(
        setNotify({
          title: 'Success',
          body: data.message,
          type: 'success'
        })
      );
    })
    .catch(error => {
      if (error.response) {
        return dispatch(
          setNotify({
            title: 'Error',
            body: error.response.data.message,
            type: 'error'
          })
        );
      }
    });
};

export const signupUser = (details, history) => dispatch => {
  dispatch(authUserRequest(true));
  return Axios.post('/user', {
    ...details
  })
    .then(async ({ data }) => {
      Axios.defaults.headers.common['x-access-token'] = data.token;
      localStorage.setItem('user', JSON.stringify(data));
      localStorage.setItem('token', data.token);
      dispatch(authUserSuccess({ ...data }));
      dispatch(
        setNotify({
          title: 'Signed up Sucessfully',
          body: data.message,
          type: 'success'
        })
      );
      return history && history.push('/onboarding');
    })
    .catch(error => {
      dispatch(authUserRequest(false));
      if (error.response) {
        dispatch(authUserError(error.response.data));
        if (error.response.data.messages) {
          error.response.data.messages.map(message => {
            return dispatch(
              setNotify({
                title: 'Error',
                body: message,
                type: 'error'
              })
            );
          });
        } else {
          return dispatch(
            setNotify({
              title: 'Error',
              body: error.response.data.message,
              type: 'error'
            })
          );
        }
      }
    });
};

export const logoutUser = history => async dispatch => {
  try {
    const kins = localStorage.getItem('kins');

    localStorage.clear();

    if (kins) {
      localStorage.setItem('kins', kins);
    }
    dispatch(logoutUserSuccess());
  } catch (error) {}
};

export const refreshUser = () => dispatch => {
  dispatch(authUserRequest(true));
  return Axios.get('/user')
    .then(async ({ data: { data, hasPassword } }) => {
      localStorage.setItem('user', JSON.stringify({ ...data, hasPassword }));
      dispatch(authUserSuccess({ ...data, hasPassword }));
    })
    .catch(error => {
      if (error.message && error.message === 'Network Error') {
        dispatch(
          setNotify({
            type: 'error',
            title: error.message,
            body: 'Please connect to the Internet'
          })
        );
      }
      dispatch(authUserError(error));
    });
};

export const uploadImage = picture => dispatch => {
  dispatch(authUserRequest(true));
  let formData = new FormData();
  formData.set('picture', picture);
  return Axios.post('/upload', formData)
    .then(({ data }) => {
      dispatch(authUserRequest(false));

      dispatch(
        setNotify({
          title: 'Profile Image Uploaded',
          body: data.message,
          type: 'success'
        })
      );
      dispatch(refreshUser());
    })
    .catch(error => {
      dispatch(authUserRequest(false));
      dispatch(authUserError(error));
    });
};

export const uploadFile = ({ document, documentName }, action) => dispatch => {
  dispatch(uploadRequest(true));
  let formData = new FormData();
  formData.set('document', document);
  formData.set('documentName', documentName);
  return Axios.post('/user/uploadfile', formData)
    .then(({ data }) => {
      dispatch(uploadRequest(false));

      dispatch(
        setNotify({
          title: 'File Image Uploaded',
          body: data.message,
          type: 'success'
        })
      );
      action && action();
      dispatch(getFiles());
    })
    .catch(error => {
      dispatch(uploadRequest(false));
    });
};

export const getFiles = () => dispatch => {
  return Axios.get('/user/uploadfile')
    .then(({ data: { data: { files } } }) => {
      dispatch(uploadSuccess(files));
    })
    .catch(error => {
      return null;
    });
};

export const subscribe = (email, action) => dispatch => {
  return Axios({
    url: 'https://analytics.firsthomesafrica.com/api/admin/subscribe',
    method: 'POST',
    data: { email },
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(({ data }) => {
      action();
      return dispatch(
        setNotify({
          title: `${email} successfully subscribed`,
          body: data.message,
          type: 'success'
        })
      );
    })
    .catch(error => {
      return null;
    });
};

export const sendMail = (details, type, action) => dispatch => {
  return Axios({
    url: `https://analytics.firsthomesafrica.com/api/admin/feedback?c=${type}`,
    method: 'POST',
    data: { ...details },
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(({ data }) => {
      action();
      return dispatch(
        setNotify({
          title: 'Message successfully sent',
          body: data.message,
          type: 'success'
        })
      );
    })
    .catch(error => {
      return null;
    });
};

export const setNOK = (details, action) => dispatch => {
  return Axios.post('/nok', { ...details })
    .then(({ data }) => {
      dispatch(getNOK());
      action && action();
      return dispatch(
        setNotify({
          title: 'Next of kin added',
          body: data.message,
          type: 'success'
        })
      );
    })
    .catch(error => {
      if (error.response) {
        return dispatch(
          setNotify({
            title: 'Next of Kin Error',
            body: error.response.data.message,
            type: 'error'
          })
        );
      }
    });
};
export const getNOK = () => dispatch => {
  return Axios.get('/nok')
    .then(({ data: { nok } }) => {
      dispatch(addNok(nok));
    })
    .catch(error => {
      return null;
    });
};
