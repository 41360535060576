export default num => {
  if (num === 2 || num === 22) {
    return `${num}nd`;
  }
  if (num === 1 || num === 21) {
    return `${num}st`;
  }
  if (num === 3 || num === 23) {
    return `${num}rd`;
  }

  return `${num}th`;
};
