import React from 'react';
import styles from './index.module.scss';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer'; import Whatsapp from '../../components/Whatsapp';
import family from '../../assets/Accelerate.png';
import vip from '../../assets/vip.svg';
import accessCard from '../../assets/accessCard.svg';
import { Link } from 'react-router-dom';

const Accelerate = () => {
  return (
    <div className={styles.accelerate}>
     <Whatsapp />
      <Nav showBackground address />
      <div className={styles.main}>
        <div className={styles.banner}>
          <div className={styles.image}>
            <img src={family} alt='family' />
          </div>
          <div className={styles.detail}>
            <div className={styles.title}>Move in less than a year!</div>
            <div className={styles.desc}>
              Sign up now and make a downpayment of up to 20% and move into your
              apartment in 9 months.
            </div>

            <div className={styles.links}>
              <Link to='signup' className={styles.dark}>
                Get Started
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.vip}>
          <img src={vip} alt='vip' />
          <p className={styles.text}>
            If you have the funds and will like to change your status from
            tenant to homeowner in less than a year, then the accelerate scheme
            is for you.
          </p>
        </div>

        <div className={styles.group}>
          <div className={styles.card}>
            <div className={styles.num}>1.</div>
            <div className={styles.head}>
              Sign Up & <br />
              Make down payment
            </div>
            <div className={styles.sub}>
              Select your preferences and make initial down payment in order to
              move to your home faster than the advertised 15 months️, <br />
              10% Qualify in 13 months <br />
              15% Qualify in 11 months <br />
              20% Qualify in 9 months <br />
              You can withdraw your down payment with 90 days notice.
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.num}>2.</div>
            <div className={styles.head}>Monthly Savings</div>
            <div className={styles.sub}>
              Make monthly equity contribution based on your chosen preferences.
              While saving you can withdraw your funds at any time
              <span role='img' aria-labelledby='emoji'>
                👌
              </span>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.num}>3.</div>
            <div className={styles.head}>Pre-qualify</div>
            <div className={styles.sub}>
              You are allocated a property which is in the final stage of
              construction. You can still withdraw funds or exit the scheme with
              a 90 day notice
              <span role='img' aria-labelledby='emoji'>
                🏆
              </span>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.num}>4.</div>
            <div className={styles.head}>Qualify due diligence & Move-in</div>
            <div className={styles.sub}>
              You initiate your option to enter into a lease to own contract,
              due diligence is carried out, contract signed and keys handed
              over.
              <br />
              <br />
              Congratulations you are a home owner!
              <span role='img' aria-labelledby='emoji'>
                💃🏽🕺🏽
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.cover}>
        <div className={styles.layout}>
          <img src={accessCard} alt='card' />
          <p>
            It doesn't stop there... as VIP with FirstHomes Africa, You get
            preferences and discounts with our partner hotels, restaurants,
            travel agents, bars and clubs. An exhaustive list of partners is
            available under FAQ’s.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Accelerate;
