import React from 'react';
import styles from './index.module.scss';
import whatsapp from '../../assets/whatsapp.png';
export default () => (
  <div className={styles.main}>
    <div className={styles.inner}>
      <img src={whatsapp} height='20' alt='whatsapp' />
      <a href='tel:+2349013000001'>+234 901 300 0001</a>
    </div>
  </div>
);
