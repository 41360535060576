import React, { useState } from "react";

import Card from "../../../components/Card";
import Panel from "../../../components/Panel";
import Tabs from "../../../components/Tabs";
import styles from "./index.module.scss";
import Table from "../../../components/Table";
import DropDown from "../../../components/DropDown";
import { useMappedState, useDispatch } from "redux-react-hook";
import { deleteCard } from "../../../store/actions/card";
import CardProcess from "../../../components/CardProcess";
import filterArray from "../../../utils/filterArray";
import xlsExport from "xlsexport";
import "jspdf-autotable";
import * as jsPDF from "jspdf";
import { setNotify } from "../../../store/actions/notify";
const Vault = () => {
  const dispatch = useDispatch();
  const deposits = useMappedState(({ wallet }) => wallet.deposits);
  const withdrawals = useMappedState(({ wallet }) => wallet.withdrawals);
  const cards = useMappedState(({ card }) => card.cards);
  const plan = useMappedState(({ onboard }) => onboard.calculation);

  const [current, setCurrent] = useState(0);
  const [types, setType] = useState({
    deposits: {},
    withdrawal: {}
  });
  const action = {
    start: () => setCurrent(0),
    first: () => setCurrent(1),
    card: () => setCurrent(2),
    success: () => setCurrent(3),
    setDate: () => setCurrent(4)
  };
  const exportCSV = (data, type) => {
    const xlsData = new xlsExport(data, type);
    return xlsData.exportToCSV(`${type}-${new Date().toDateString()}.csv`);
  };
  const exportPDF = type => {
    const doc = new jsPDF();

    doc.autoTable({ html: `#${type}` });
    doc.save(`${type}-${new Date().toDateString()}.pdf`);
  };

  return (
    <div className={styles.vault}>
      <div className={styles.title}>Vault</div>
      <Tabs>
        <Panel title="Deposits">
          {deposits.length > 0 ? (
            <>
              <div className={styles.topPart}>
                <div>
                  <DropDown
                    data={[
                      {
                        name: "Select contribution type",
                        action: () =>
                          setType({
                            ...types,
                            deposits: {
                              ...types.deposits,
                              type: {}
                            }
                          })
                      },
                      {
                        name: "Contractual Contribution",
                        action: () =>
                          setType({
                            ...types,
                            deposits: {
                              ...types.deposits,
                              type: {
                                value: ["type", "contractual"]
                              }
                            }
                          })
                      },
                      {
                        name: "Voluntary Contribution",
                        action: () =>
                          setType({
                            ...types,
                            deposits: {
                              ...types.deposits,
                              type: {
                                value: ["type", "voluntary"]
                              }
                            }
                          })
                      }
                    ]}
                  />
                  <DropDown
                    data={[
                      {
                        name: "Highest Amount",
                        action: () =>
                          setType({
                            ...types,
                            deposits: {
                              ...types.deposits,
                              amount: {
                                asc: "depositAmount"
                              }
                            }
                          })
                      },
                      {
                        name: "Lowest Amount",
                        action: () =>
                          setType({
                            ...types,
                            deposits: {
                              ...types.deposits,
                              amount: {
                                desc: "depositAmount"
                              }
                            }
                          })
                      }
                    ]}
                  />
                  <DropDown
                    data={[
                      {
                        name: "Latest Date",
                        action: () =>
                          setType({
                            ...types,
                            deposits: {
                              ...types.deposits,
                              date: {
                                ascDate: "depositDate"
                              }
                            }
                          })
                      },
                      {
                        name: "Earliest Date",
                        action: () =>
                          setType({
                            ...types,
                            deposits: {
                              ...types.deposits,
                              date: {
                                descDate: "depositDate"
                              }
                            }
                          })
                      }
                    ]}
                  />
                </div>
                <div>
                  <button onClick={() => exportCSV(deposits, "deposits")}>
                    Export CSV <span className={styles.icon} />
                  </button>
                  <button onClick={() => exportPDF("deposits")}>
                    Export PDF <span className={styles.icon} />
                  </button>
                </div>
              </div>
              <Table
                title="deposits"
                showFoot
                header={[
                  "Amount",
                  "Date",
                  "type",
                  "Points",
                  "status",
                  "Ref. code"
                ]}
                data={filterArray(deposits, types["deposits"])}
                conditions={{
                  badge: "successful"
                }}
                dataType={[
                  { depositAmount: "cash" },
                  { depositDate: "text" },
                  { type: "text" },
                  { pointsGained: "text" },
                  { status: "badge" },
                  { reference: "truncate" }
                ]}
              />
            </>
          ) : (
            <div className={styles.none}>No Deposits</div>
          )}
        </Panel>

        <Panel title="withdrawals">
          {withdrawals.length > 0 ? (
            <>
              <div className={styles.topPart}>
                <div>
                  <DropDown
                    data={[
                      {
                        name: "Select withdrawal type",
                        action: () =>
                          setType({
                            ...types,
                            withdrawal: {
                              ...types.withdrawal,
                              type: {}
                            }
                          })
                      },
                      {
                        name: "Instant Withdrawal",
                        action: () =>
                          setType({
                            ...types,
                            withdrawal: {
                              ...types.withdrawal,
                              type: {
                                value: ["typeOfWithdrawal", "instant"]
                              }
                            }
                          })
                      },
                      {
                        name: "Delayed Withdrawal",
                        action: () =>
                          setType({
                            ...types,
                            withdrawal: {
                              ...types.withdrawal,
                              type: {
                                value: ["typeOfWithdrawal", "delayed"]
                              }
                            }
                          })
                      }
                    ]}
                  />
                  <DropDown
                    data={[
                      {
                        name: "Highest Amount",
                        action: () =>
                          setType({
                            ...types,
                            withdrawal: {
                              ...types.withdrawal,
                              amount: {
                                asc: "amount"
                              }
                            }
                          })
                      },
                      {
                        name: "Lowest Amount",
                        action: () =>
                          setType({
                            ...types,
                            withdrawal: {
                              ...types.withdrawal,
                              amount: {
                                desc: "amount"
                              }
                            }
                          })
                      }
                    ]}
                  />
                  <DropDown
                    data={[
                      {
                        name: "Latest Date",
                        action: () =>
                          setType({
                            ...types,
                            withdrawal: {
                              ...types.withdrawal,
                              date: {
                                ascDate: "withdrawalDate"
                              }
                            }
                          })
                      },
                      {
                        name: "Earliest Date",
                        action: () =>
                          setType({
                            ...types,
                            withdrawal: {
                              ...types.withdrawal,
                              date: {
                                descDate: "withdrawalDate"
                              }
                            }
                          })
                      }
                    ]}
                  />
                </div>
                <div>
                  <button onClick={() => exportCSV(withdrawals, "withdrawals")}>
                    Export CSV <span className={styles.icon} />
                  </button>
                  <button onClick={() => exportPDF("withdrawals")}>
                    Export PDF <span className={styles.icon} />
                  </button>
                </div>
              </div>

              <Table
                title="withdrawals"
                showFoot
                header={[
                  "Amount",
                  "Date",
                  "type",
                  "Points",
                  "status",
                  "Ref. code"
                ]}
                data={filterArray(withdrawals, types["withdrawal"])}
                conditions={{
                  badge: "SUCCESSFUL"
                }}
                dataType={[
                  { amount: "cash" },
                  { withdrawalDate: "text" },
                  { typeOfWithdrawal: "text" },
                  { penaltyPercent: "text" },
                  { status: "badge" },
                  { reference: "truncate" }
                ]}
              />
            </>
          ) : (
            <div className={styles.none}>No Withdrawals</div>
          )}
        </Panel>

        <Panel title="manage cards">
          <CardProcess current={current} action={action}>
            {
              <div className={styles.cards}>
                {cards.map((card, i) => (
                  <Card
                    key={i}
                    name={card.type}
                    digits={card.last4Digits}
                    position={i}
                    remove={() => dispatch(deleteCard(card._id))}
                  />
                ))}

                <button
                  onClick={
                    plan
                      ? action["first"]
                      : () =>
                          dispatch(
                            setNotify({
                              type: "error",
                              title: "You have not set up your housing plan",
                              body: "Go to Account -> modify plan"
                            })
                          )
                  }
                >
                  add new card
                </button>
              </div>
            }
          </CardProcess>
        </Panel>
      </Tabs>
    </div>
  );
};

export default Vault;
