import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import fb from '../../assets/fb.svg';
import insta from '../../assets/insta.svg';
import linkedin from '../../assets/linkedin.svg';
import logo from '../../assets/navLogoDark.svg';
import secure from '../../assets/secure.svg';
import styles from './index.module.scss';
import { subscribe } from '../../store/actions/auth';
import twitter from '../../assets/twitter.svg';
import { useDispatch } from 'redux-react-hook';

const Footer = props => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const Linker = ({ title, data, getStarted, socials }) => (
    <div className={styles.linker}>
      <div className={styles.title}> {title} </div>
      <div className={styles.links}>
        {data &&
          data.map(datum =>
            datum.ext ? (
              <a
                key={datum.name}
                target='_blank'
                rel='noopener noreferrer'
                href={datum.path} //'/contact'
              >
                {datum.name}
              </a>
            ) : (
              <Link key={datum.name} to={datum.path}>
                {datum.name}
              </Link>
            )
          )}
        {socials && (
          <div className={styles.socials}>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://linkedin.com/company/firsthomeafrica'
            >
              <img src={linkedin} alt='linkedin' />
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://facebook.com/firsthomesafrica'
            >
              <img src={fb} alt='facebook' />
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://twitter.com/firsthomeafrica'
            >
              <img src={twitter} alt='twitter' />
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://instagram.com/firsthomeafrica'
            >
              <img src={insta} alt='insta' />
            </a>
          </div>
        )}
        {getStarted && (
          <Link className={styles.btn} to='signup'>
            Get Started
          </Link>
        )}
      </div>
    </div>
  );

  return (
    <div className={styles.footer}>
      <div className={styles.details}>
        <div>
          <img src={logo} alt='logo' />
        </div>{' '}
        <div style={{ padding: '10px 0' }}>
          <div className={styles.address}>2B Babalola Gardens</div>
          <div className={styles.address}>
            off Freedom way, Lekki Phase 1, Lagos
          </div>
          <br />
          <div className={styles.address}>+234 700 800 0500</div>
        </div>
        <div className={styles.suscribe}>
          <form
            className={styles.form}
            onSubmit={e => {
              e.preventDefault();
              dispatch(subscribe(email, () => setEmail('')));
            }}
          >
            <label> Subscribe to our newsletter </label>{' '}
            <div>
              <input
                className={styles.input}
                type='email'
                name='email'
                value={email}
                placeholder='Email address'
                onChange={e => setEmail(e.target.value)}
              />
              <input
                className={styles.btn}
                type='submit'
                name=''
                value='SUBSCRIBE'
              />
            </div>
          </form>
        </div>
        <div className={styles.copy}> &#169; 2019 All rights reserved</div>
        <div className={styles.secure}>
          <img src={secure} alt='secure' /> Secured by SSQL{' '}
        </div>
      </div>
      <div className={styles.nav}>
        <div className={styles.linkers}>
          <Linker
            title='Navigation'
            data={[
              { name: 'Homepage', path: '/' },
              //{ name: "Get started", path: "/signup" },
              { name: 'Sign in', path: '/signin' },
              // { name: "How it works", path: "/how-it-works" },
              { name: 'Gallery', path: '/gallery' },
              { name: 'Careers', path: '/careers' },
              { name: 'Feedback & complaints ', path: '/complaint' }
            ]}
          />
          <Linker
            title='Get informed'
            data={[
              { name: 'FAQs', path: '/faqs' },
              // { name: "Security", path: "/security" },
              { name: 'Privacy policy', path: '/privacy' },
              { name: 'Terms of use', path: '/terms' }
            ]}
          />
          <Linker
            title='Discover'
            data={[
              // { name: "Blog", path: "/blog" },
              { name: 'Our story', path: '/about' },
              {
                name: 'Contact us',
                path:
                  'https://bitrix24public.com/firsthomesafrica.bitrix24.com/form/1_contact_info/z1lpvl/',
                ext: true
              }
            ]}
            getStarted
            socials
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
