import React, { useState } from 'react';

import Footer from '../../components/Footer'; import Whatsapp from '../../components/Whatsapp';
import banner from '../../assets/career.png';
import spinner from '../../assets/spinner.svg';
import Nav from '../../components/Nav';
import styles from './index.module.scss';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { setNotify } from '../../store/actions/notify';
import { uploadResume } from '../../store/actions/career';

const CareerApply = ({ history, location }) => {
  const dispatch = useDispatch();
  const { uploading } = useMappedState(({ auth }) => auth);

  const [form, setForm] = useState({});
  const onChange = e => {
    const { name, value, type, checked } = e.target;

    return setForm({
      ...form,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const onAdd = e => {
    setForm({
      ...form,
      cv: e.target.files[0],
      file: e.target.files[0].name
    });
  };
  const onSubmit = e => {
    const q = new URLSearchParams(location.search);
    const { name, introduction, agree, correct, cv } = form;
    console.log(form);
    if (!agree || !name || !introduction || !correct || !cv) {
      return dispatch(
        setNotify({
          type: 'error',
          body: 'Fill all form and check all fields to proceed',
          title: 'Application Form Error'
        })
      );
    }
    return dispatch(
      uploadResume(
        {
          name,
          introduction,
          cv,
          role: q.get('role')
        },
        () => history.push('/careers')
      )
    );
  };
  return (
    <div className={styles.career}>
     <Whatsapp />
      <Nav showBackground address />
      <div className={styles.main}>
        <div
          className={styles.back}
          style={{
            background: `url(${banner})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div className={styles.detail}>Careers at FirstHomes Africa</div>
        </div>

        <div className={styles.content}>
          <div className={styles.top}>CV Upload</div>

          <div className={styles.apply}>
            <div className={styles.head}>
              Privacy - Personally Identifiable Information
            </div>
            <p>
              Your application to FirstHomes Africa will involve providing us with
              personally identifiable information which we collect and use to
              evaluate and administer your application and, if successful
              administer your contract of employment.
            </p>{' '}
            <p>
              I have read the Privacy Policy and consent to use of my personal
              information for the purpose of evaluating and administering my
              application and if successful my contract of employment with
              FirstHomes Africa.
            </p>
            <div className={styles.check}>
              <input
                type='checkbox'
                onChange={onChange}
                name='agree'
                id='agree'
              />
              <label htmlFor='agree'>*I Agree</label>
            </div>
            <div className={styles.head}>CV Upload</div>
            <div className={styles.form}>
              <input
                type='text'
                name='name'
                onChange={onChange}
                placeholder='Full Name'
              />
              <textarea
                type='text'
                name='introduction'
                onChange={onChange}
                placeholder='Give a brief why you are suitable for this position ...'
              />
            </div>
            <div className={styles.check}>
              <label htmlFor='correct'>
                *The information I have provided is accurate:
              </label>
              <input
                type='checkbox'
                onChange={onChange}
                name='correct'
                id='correct'
              />
            </div>
            <div className={styles.upload}>
              <p>Upload your CV (PDF only):</p>
              <div>
                <label htmlFor='upload'>
                  {form.file ? form.file : 'Add your CV'}
                </label>
                <input type='file' name='cv' id='upload' onChange={onAdd} />
              </div>
            </div>
            <div>
              <button className={styles.submit} onClick={onSubmit}>
                {uploading ? (
                  <img src={spinner} alt='' />
                ) : (
                  <span>Submit Application</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CareerApply;
