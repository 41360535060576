import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import React from 'react';
import styles from './index.module.scss';

const NotFound = props => {
  return (
    <div className={styles.notfound}>
      <Nav />
      <div className={styles.main}>
        <div className={styles.text}>Page Not Found</div>
        <div>
          <div className={styles.not}>
            <div>4</div>
            <div>
              <i className='lni-emoji-sad'></i>
            </div>
            <div>4</div>
          </div>
        </div>
        <div>
          <Link to='/' className={styles.blueBtn}>
            Go Back Home
          </Link>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default NotFound;
