import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useMappedState } from "redux-react-hook";
const ProtectedRoute = ({ component: Component, ...configs }) => {
  const user = useMappedState(({ auth }) => auth.currentUser);
  // const calculation = useMappedState(({ onboard }) => onboard.calculation);
  // console.log(user);

  return (
    <Route
      {...configs}
      render={props =>
        user ? (
          user.hasPassword ? (
            <Component {...props} />
          ) : (
            <Redirect to="/onboarding" />
          )
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );
};

export default ProtectedRoute;
