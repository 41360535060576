import Footer from '../../components/Footer';
import Nav from '../../components/Nav';
import { NavLink } from 'react-router-dom';
import React from 'react';
import Whatsapp from '../../components/Whatsapp';
import banner from '../../assets/WHY.jpg';
import bias from '../../assets/wk-bias.svg';
import board from '../../assets/board.png';
import budget from '../../assets/wk-budget.svg';
import dash from '../../assets/wk-dash.svg';
import exit from '../../assets/wk-exit.svg';
import green from '../../assets/wk-green.svg';
import hidden from '../../assets/wk-hidden.svg';
import life from '../../assets/wk-life.svg';
import logo from '../../assets/logobigw.svg';
import pace from '../../assets/wk-pace.svg';
import peace from '../../assets/wk-peace.svg';
import reward from '../../assets/wk-reward.svg';
import scale from '../../assets/wk-scale.svg';
import simple from '../../assets/wk-simple.svg';
import styles from './index.module.scss';
import warranty from '../../assets/wk-warranty.svg';
import withdraw from '../../assets/wk-withdraw.svg';

const howItWorks = ({ match: { params } }) => {
  const details = {
    unique: {
      board:
        'A home ownership scheme designed to cater for your needs, aspirations and lifestyle.',
      items: [
        {
          title: 'Budget Friendly',
          icon: budget,
          desc:
            'FirstHomes Africa apartment sizes considers the various stages in your life. You may start out single and only need a studio but you may find your soul mate and need to expand your space to meet the changing needs of this exciting new season of life! We won’t push you to purchase a home that is more than what you need and out of your budget.'
        },
        {
          icon: life,
          title: 'Lifestyle',
          desc:
            'To us, affordable housing means optimising space and design. It also means removing waste from the building process. That’s why our apartment sizes and layout are standardized which allows us to pass on these savings to you. However, affordable housing does not mean "out of town" as we consider the transport links to the city centre when selecting locations in which to build.'
        },
        {
          icon: warranty,
          title: 'Warranty',
          desc:
            'Every one of our properties come with a three year warranty. Which means within the first three years of moving in, you won’t need to worry about unexpected costs that can catch any homeowner off guard such as - structural repairs or general maintenance of your home. After three years, you begin paying for repairs but the hassle of organising the repairs and making sure the workmanship is quality remains our responsibility.'
        },
        {
          icon: green,
          title: 'Green Living',
          desc:
            'The future of home ownership is “technology”, so we build “smart and green homes” with the use of solar power in the communal areas and all utilities pre-installed. All you have to do is to activate the general services, pay via mobile banking and you are "good to go".'
        }
      ]
    },
    transparent: {
      board:
        'We build your credit history with you, whilst you remain in control of your monthly payments. ',
      items: [
        {
          icon: dash,
          title: 'Performance Based',
          desc:
            'Your employment, earning history/status, educational background and age gives us an insight into your earning potential, your deposit habit on the platform informs your credit worthiness.'
        },
        {
          icon: hidden,
          title: 'No Hidden Fees',
          desc: 'There are no hidden fees, what is advertised is what you pay.'
        },
        {
          icon: bias,
          title: 'No Bias',
          desc:
            'All barriers have been removed from the application process. It is not about "who you know" but about "how you behave".'
        },
        {
          icon: reward,
          title: 'Reward',
          desc:
            'We encourage good saving habits and reward consistency with lower interest rates.'
        }
      ]
    },
    flexible: {
      board:
        'We know that life is unpredictable and that’s why we’ve structured our services so that we can ride the highs and lows with you.',
      items: [
        {
          icon: scale,
          title: 'Scale up or down',
          desc:
            'When life throws you a curve ball, you can downsize to any available FirstHomes Africa apartments you can afford. Likewise, when there is a windfall, you can upscale and each time carrying your existing equity with you.'
        },
        {
          icon: withdraw,
          title: 'Instant Withdrawal',
          desc:
            'Strapped for cash? You can withdraw instantly to meet your needs.'
        },
        {
          icon: exit,
          title: 'Easy Exit',
          desc:
            'Meeting your monthly payment obligation is too much of a burden? You can cash out of the scheme and take advantage of the property appreciation.'
        }
      ]
    },
    control: {
      board: 'A home ownership scheme that empowers as opposed to enslaves.',
      items: [
        {
          icon: pace,
          title: 'Pace',
          desc:
            'Owning your home is not a race, neither is it a sprint. You can proceed at your own pace with optional loan tenures of up to 15 years. In addition, You can elect to maintain the agreed monthly payments or increase your equity contribution with voluntary payments. We enable you proceed in  a manner that works for you.'
        },
        {
          icon: peace,
          title: 'Peace of mind',
          desc:
            'Your equity contribution is stored in a vault from which you can withdraw at any time until you sign your lease agreement.'
        },
        {
          icon: simple,
          title: 'Keep it simple',
          desc:
            'You do not need a dictionary or a professional to interpret our contract terms. Our focus is to get you into your apartment and not to trap you with ambiguous terms.'
        }
      ]
    }
  };
  return (
    <div className={styles.howItWorks}>
      <Whatsapp />
      <Nav showBackground address />
      <div className={styles.main}>
        <div
          className={styles.back}
          style={{
            background: `url(${banner}) center center`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <img src={logo} alt='' />
        </div>
        <div className={styles.header}>
          <div className={styles.tabs}>
            <NavLink activeClassName={styles.active} to='/how-it-works/unique'>
              Unique
            </NavLink>
            <NavLink
              activeClassName={styles.active}
              to='/how-it-works/transparent'
            >
              Transparent
            </NavLink>
            <NavLink
              activeClassName={styles.active}
              to='/how-it-works/flexible'
            >
              Flexible
            </NavLink>
            <NavLink activeClassName={styles.active} to='/how-it-works/control'>
              Control
            </NavLink>
          </div>
        </div>

        <div className={styles.content}>
          <div
            className={styles.board}
            style={{
              background: `url(${board}) center center`,
              backgroundSize: 'cover'
            }}
          >
            <span>{details[params.feature].board}</span>
          </div>

          <div className={styles.items}>
            {details[params.feature].items.map((item, i) => (
              <div key={i} className={styles.item}>
                <img src={item.icon} alt='icon' />
                <div className={styles.title}>{item.title}</div>
                <div className={styles.desc}>{item.desc}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default howItWorks;
