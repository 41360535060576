import React from 'react';
import gallery1 from '../../../assets/gallery1.jpeg';
import gallery2 from '../../../assets/gallery2.jpeg';
import gallery3 from '../../../assets/gallery3.jpeg';
import gallery4 from '../../../assets/gallery4.jpeg';
import styles from './index.module.scss';

const BlockWise = () => {
  return (
    <div className={styles.save}>
      <div className={styles.head}>
        <div className={styles.title}>Gallery</div>
        {/* <div className={styles.sub}>
          Empowering individuals with personal financial & wealth management
          tools
        </div> */}
      </div>
      <div className={styles.new}>
        <div className={styles.cards}>
          <div className={styles.card}>
            <img src={gallery1} alt='' />
          </div>
          <div className={styles.card}>
            <img src={gallery2} alt='' />
          </div>
          <div className={styles.card}>
            <img src={gallery3} alt='' />
          </div>
          <div className={styles.card}>
            <img src={gallery4} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlockWise;
