import styles from "./index.module.scss";
import React, { useState, useEffect } from "react";
import spinner from "../../../../assets/spinnerblue.svg";
import { useMappedState, useDispatch } from "redux-react-hook";
import SelectInput from "../../../../components/SelectInput";
import {
  getLocations,
  calculateHousing
} from "../../../../store/actions/onboard";
import includesAll from "../../../../utils/includesAll";
import { setNotify } from "../../../../store/actions/notify";
import currency from "../../../../utils/currency";
import CurrencyInput from "../../../../components/CurrencyInput";
import properNum from "../../../../utils/properNum";

const Plan = () => {
  const [current, setCurrent] = useState(0);
  const dispatch = useDispatch();
  const [selects, setSelects] = useState({});
  const [details, setDetails] = useState({});

  const user = useMappedState(({ auth }) => auth.currentUser);
  const { calculation: plan, downPaymentStatus } = useMappedState(
    ({ onboard }) => onboard
  );
  const onboard = useMappedState(({ onboard }) => onboard);
  const downPayAvailable =
    user &&
    downPaymentStatus &&
    !user.hasMadeFirstPayment &&
    (user.hasDownpayment === "yes" &&
      downPaymentStatus.paymentStatus !== "FULL");
  useEffect(() => {
    setDetails({});
    setSelects({});
  }, []);
  const nextPage = e => {
    e && e.preventDefault();
    if (current < 2) {
      setCurrent(current + 1);
    }
  };

  const HouseTypes = onboard.houseTypes.map(house => ({
    label: house.description,
    value: house.houseType
  }));
  const states = onboard.states.map(state => ({
    label: state,
    value: state
  }));

  const locations = onboard.locations.map(location => ({
    label: location,
    value: location
  }));

  const years = [...Array(14).keys()].map(num => ({
    // label: `${num + 1} year${num + 1 === 1 ? "" : "s"}`,
    label: `${num + 2} years`,
    value: num + 2
  }));

  const percents = [...Array(5).keys()].map(num => ({
    label: `${(num + 2) * 5} %`,
    value: (num + 2) * 5
  }));

  const handleSelect = (data, name) => {
    if (name === "state") {
      setSelects({
        ...selects,
        location: { value: "", label: "" }
      });
      dispatch(getLocations(data.value));
    }
    setSelects({
      ...selects,
      [name]: data
    });

    setDetails({
      ...details,
      [name]: data.value
    });
  };

  const gatherValue = e => {
    e && e.preventDefault();
    if (e.target.name === "housePrice") {
      if (
        e.target.value.length &&
        isNaN(e.target.value[e.target.value.length - 1])
      )
        return;
    }
    setDetails({
      ...details,
      [e.target.name]: e.target.value
    });
  };

  const prevPage = e => {
    e && e.preventDefault();
    if (current >= 1) {
      setCurrent(current - 1);
    }
  };

  const calculateCost = e => {
    e && e.preventDefault();
    if (
      !includesAll(Object.keys(details), [
        "housePrice",
        "houseType",
        "state",
        "location",
        "loanTerm",
        ...(downPayAvailable ? ["hasDownPayment"] : []),
        ...(details && details.hasDownPayment === "yes"
          ? ["downPaymentPercent"]
          : [])
      ])
    ) {
      return dispatch(
        setNotify({
          type: "error",
          title: "All fields required",
          body: "Insert all fields correctly"
        })
      );
    }
    dispatch(
      calculateHousing(
        {
          ...details,
          housePrice: properNum(details.housePrice),
          ...(downPayAvailable ? {} : { hasDownPayment: "no" })
        },
        nextPage
      )
    );
  };
  const renderPage = {
    0: (
      <div className={styles.main}>
        <div className={styles.top}>
          <div className={styles.title}>Current Housing Plan</div>
          <div className={styles.btns}>
            <div className={styles.btn}>
              <button onClick={nextPage}>edit</button>
            </div>
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>Apartment type</div>
          <div className={styles.item}>{plan && plan.houseType}</div>
        </div>
        <div className={styles.list}>
          <div className={styles.head}>Apartment Budget</div>
          <div className={styles.item}>
            &#8358;{" "}
            {plan && (currency(plan.housePrice) || currency(plan.totalBudget))}
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.head}>State</div>
          <div className={styles.item}>{plan && plan.state}</div>
        </div>
        <div className={styles.list}>
          <div className={styles.head}>Neighbourhood</div>
          <div className={styles.item}>{plan && plan.location}</div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>MONTHLY CONTRIBUTION</div>
          <div className={styles.item}>
            &#8358; {plan && currency(plan.estimatedMonthlyPament)}
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>ESTIMATED CONTRIBUTION PERIOD</div>
          <div className={styles.item}>
            {plan && plan.savingsDuration} month
            {plan && plan.savingsDuration <= 1 ? "" : "s"}
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>TOTAL CONTRIBUTION</div>
          <div className={styles.item}>
            &#8358; {plan && currency(plan.totalEquityContribution)}
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>ESTIMATED MATURITY DATE</div>
          <div className={styles.item}>{plan && plan.maturityDate}</div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>TOTAL LOAN AMOUNT</div>
          <div className={styles.item}>
            &#8358; {plan && currency(plan.loanAmount)}
          </div>
        </div>

        {plan && plan.downPaymentPercentage !== 0 && (
          <div className={styles.list}>
            <div className={styles.head}>DOWN PAYMENT</div>
            <div className={styles.item}>
              &#8358; {plan && currency(plan.downPaymentAmount)} (
              {plan.downPaymentPercentage}%)
            </div>
          </div>
        )}

        <div className={styles.list}>
          <div className={styles.head}>MAXIMUM EQUITY REPAYMENT PERIOD</div>
          <div className={styles.item}>
            {plan && plan.loanTerm} year{plan && plan.loanTerm <= 1 ? "" : "s"}
          </div>
        </div>
      </div>
    ),
    1: (
      <div className={styles.main}>
        <div className={`${styles.top} ${styles.break}`}>
          <div className={styles.title}>Edit Apartment Plan</div>
          <div className={styles.btns}>
            {onboard.calculating ? (
              <img
                src={spinner}
                style={{
                  height: "50px"
                }}
                alt=""
              />
            ) : (
              <div className={styles.btn}>
                <button onClick={calculateCost}>Calculate</button>
              </div>
            )}
            <div className={styles.btn}>
              <button onClick={prevPage}>Back</button>
            </div>
          </div>
        </div>

        <div className={styles.form}>
          <div className={styles.select}>
            <label>Apartment Type</label>

            <SelectInput
              changer={data => handleSelect(data, "houseType")}
              blue
              value={selects.houseType}
              noborder
              placeholder="Apartment Type"
              data={HouseTypes}
            />
          </div>
          <div className={styles.select}>
            <label>State</label>

            <SelectInput
              blue
              value={selects.state}
              changer={data => handleSelect(data, "state")}
              noborder
              placeholder="State"
              data={states}
            />
          </div>

          {!onboard.loading && locations.length > 0 && (
            <div className={styles.select}>
              <label>Location</label>

              <SelectInput
                changer={data => handleSelect(data, "location")}
                blue
                value={selects.location}
                noborder
                placeholder="Locations"
                data={locations}
              />
            </div>
          )}
          {onboard.loading && (
            <img
              src={spinner}
              style={{
                height: "50px",
                alignSelf: "center"
              }}
              alt=""
            />
          )}

          <div className={styles.input}>
            <label> Apartment Price</label>
            <CurrencyInput
              name="housePrice"
              holder="Apartment Price"
              num={details.housePrice}
              onchange={gatherValue}
            />
            {/* <input
              type="number"
              name="housePrice"
              placeholder="Apartment Price"
              value={details.housePrice}
              onChange={gatherValue}
            /> */}
          </div>
          {downPayAvailable && (
            <div className={styles.select}>
              <label>Do you have a down payment ?</label>

              <SelectInput
                changer={data => handleSelect(data, "hasDownPayment")}
                blue
                value={selects.hasDownPayment}
                noborder
                placeholder="Do you have a down payment ?"
                data={[
                  { label: "Yes", value: "yes" },
                  { label: "No", value: "no" }
                ]}
              />
            </div>
          )}
          {details.hasDownPayment === "yes" && (
            <div className={styles.select}>
              <label>Down Payment Percentage</label>

              <SelectInput
                changer={data => handleSelect(data, "downPaymentPercent")}
                blue
                value={selects.downPaymentPercent}
                noborder
                placeholder="Select Down payment Percentage"
                data={percents}
              />
            </div>
          )}
          <div className={styles.select}>
            <label>Loan Term</label>

            <SelectInput
              changer={data => handleSelect(data, "loanTerm")}
              blue
              value={selects.loanTerm}
              noborder
              placeholder="Select Loan Term"
              data={years}
            />
          </div>
        </div>
        <div className={`${styles.top} ${styles.break}`}>
          <div className={styles.title}></div>
          <div className={styles.btns}>
            {onboard.calculating ? (
              <img
                src={spinner}
                style={{
                  height: "50px"
                }}
                alt=""
              />
            ) : (
              <div className={styles.btn}>
                <button onClick={calculateCost}>Calculate</button>
              </div>
            )}
            <div className={styles.btn}></div>
          </div>
        </div>
      </div>
    ),
    2: (
      <div className={styles.main}>
        <div className={`${styles.top} ${styles.break}`}>
          <div className={styles.title}>Calculated Housing Plan</div>
          <div className={styles.btns}>
            <div className={styles.btn}>
              <button onClick={prevPage}>Recaculate</button>
            </div>
            <div className={styles.btn}>
              <button onClick={() => setCurrent(0)}>Exit</button>
            </div>
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>Apartment type</div>
          <div className={styles.item}>{plan && plan.houseType}</div>
        </div>
        <div className={styles.list}>
          <div className={styles.head}>State</div>
          <div className={styles.item}>{plan && plan.state}</div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>Location</div>
          <div className={styles.item}>{plan && plan.location}</div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>MONTHLY CONTRIBUTION</div>
          <div className={styles.item}>
            &#8358; {plan && currency(plan.estimatedMonthlyPament)}
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>ESTIMATED CONTRIBUTION PERIOD</div>
          <div className={styles.item}>
            {plan && plan.savingsDuration} month
            {plan && plan.savingsDuration <= 1 ? "" : "s"}
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>TOTAL CONTRIBUTION</div>
          <div className={styles.item}>
            &#8358; {plan && currency(plan.totalEquityContribution)}
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>ESTIMATED MATURITY DATE</div>
          <div className={styles.item}>{plan && plan.maturityDate}</div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>TOTAL LOAN AMOUNT</div>
          <div className={styles.item}>
            &#8358; {plan && currency(plan.loanAmount)}
          </div>
        </div>
        {plan && plan.downPaymentPercentage !== 0 && (
          <div className={styles.list}>
            <div className={styles.head}>DOWN PAYMENT</div>
            <div className={styles.item}>
              &#8358; {plan && currency(plan.downPaymentAmount)} (
              {plan.downPaymentPercentage}%)
            </div>
          </div>
        )}
        <div className={styles.list}>
          <div className={styles.head}>TOTAL BUDGET</div>
          <div className={styles.item}>
            &#8358; {plan && plan.totalBudget && currency(plan.totalBudget)}
          </div>
        </div>
      </div>
    )
  };
  return <div className={styles.plan}>{renderPage[current]}</div>;
};

export default Plan;
