import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import React from 'react';
import Whatsapp from '../../components/Whatsapp';
import banner from '../../assets/blogban.svg';
import blog1 from '../../assets/blog1.png';
import blog2 from '../../assets/blog2.png';
import blog3 from '../../assets/blog3.png';
import elips from '../../assets/elips.svg';
import styles from './index.module.scss';

const Blog = ({ history }) => {
  const articles = [
    {
      image: blog1,
      title: 'Hidden costs of buying a home',
      desc:
        'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. ',
      day: '09',
      month: 'jul',
      year: '2019',
      publisher: 'Company News'
    },
    {
      image: blog2,
      title: 'Hidden costs of buying a home',
      desc:
        'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. ',
      day: '03',
      month: 'jul',
      year: '2019',
      publisher: 'Company News'
    },
    {
      image: blog3,
      title: 'Hidden costs of buying a home',
      desc:
        'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. ',
      day: '03',
      month: 'jul',
      year: '2019',
      publisher: 'Company News'
    },
    {
      image: blog1,
      title: 'Hidden costs of buying a home',
      desc:
        'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. ',
      day: '03',
      month: 'jul',
      year: '2019',
      publisher: 'Company News'
    },
    {
      image: blog3,
      title: 'Hidden costs of buying a home',
      desc:
        'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. ',
      day: '03',
      month: 'jul',
      year: '2019',
      publisher: 'Company News'
    },
    {
      image: blog2,
      title: 'Hidden costs of buying a home',
      desc:
        'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. ',
      day: '03',
      month: 'jul',
      year: '2019',
      publisher: 'Company News'
    },
    {
      image: blog3,
      title: 'Hidden costs of buying a home',
      desc:
        'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. ',
      day: '03',
      month: 'jul',
      year: '2019',
      publisher: 'Company News'
    },
    {
      image: blog1,
      title: 'Hidden costs of buying a home',
      desc:
        'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. ',
      day: '03',
      month: 'jul',
      year: '2019',
      publisher: 'Company News'
    }
  ];
  return (
    <div className={styles.blog}>
      <Whatsapp />
      <Nav showBackground address />
      <div className={styles.main}>
        <div
          className={styles.back}
          style={{
            backgroundImage: `url(${banner})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
          }}
        >
          <div>
            <div className={styles.title}>FirstHomes Africa Blog</div>
            <div className={styles.sub}>
              Tips and news - for real estate literacy.
            </div>
          </div>
        </div>
        <div className={styles.cat}>
          <button className={styles.active}>ALL</button>
          <button>MONEY</button>
          <button>LIFESTYLE</button>
          <button>NEWS</button>
        </div>
      </div>
      <div className={styles.articles}>
        {articles &&
          articles.map((article, i) => (
            <div key={i} className={styles.article}>
              <div className={styles.grp}>
                <div className={styles.date}>
                  <span>{article.month}</span>
                  <span className={styles.day}>{article.day}</span>
                  <span>{article.year}</span>
                </div>
                <div className={styles.image}>
                  <img src={article.image} alt='article' />
                </div>
              </div>
              <div className={styles.details}>
                <div className={styles.title}>{article.title}</div>
                <div className={styles.pub}>{article.publisher}</div>
                <div className={styles.desc}>{article.desc}</div>
                <Link to='/blog/1'>Read More</Link>
              </div>
            </div>
          ))}
      </div>
      <div className={styles.pages}>
        <button className={styles.active}>1</button>
        <button>2</button>
        <button>3</button>
        <span>
          <img src={elips} alt='elipsis' />
        </span>
        <button>Next</button>
      </div>

      <Footer />
    </div>
  );
};

export default Blog;
