import React, { useState } from "react";
import styles from "./index.module.scss";

const Folder = ({ title, body, green, big }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.fold}>
      <div
        className={`${styles.title} ${green ? styles.green : ""}`}
        onClick={() => setOpen(!open)}
      >
        {title}{" "}
        <span className={big ? styles.big : ""}>{open ? "-" : "+"}</span>
      </div>
      {open && (
        <div className={`${styles.body} ${big ? styles.big : ""}`}>{body}</div>
      )}
    </div>
  );
};

export default Folder;
