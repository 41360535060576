import React, { useState } from 'react';
import { addCard, redirectCard, validateCard } from '../../store/actions/card';
import { useDispatch, useMappedState } from 'redux-react-hook';

import BarLoader from 'react-spinners/BarLoader';
import { css } from '@emotion/core';
import currency from '../../utils/currency';
import dater from '../../utils/dater';
import fly from '../../assets/fly.svg';
import { setNotify } from '../../store/actions/notify';
import { setPlanDate } from '../../store/actions/onboard';
import styles from './index.module.scss';

const CardProcess = ({ children, current, action, first }) => {
  const dispatch = useDispatch();
  const user = useMappedState(({ auth }) => auth.currentUser);
  const plan = useMappedState(({ onboard }) => onboard.calculation);
  let inputs = React.createRef();

  const {
    type,
    suggested_auth,
    loading,
    transaction_reference,
    authUrl,
    flwref,
    reference
  } = useMappedState(({ card }) => card);

  // useEffect(() => {
  //   if (!type) {
  //     // clearInputs();
  //   }
  // }, [type]);

  const [date, setDate] = useState(1);
  const [cardDetail, setCardDetail] = useState({});
  const clearInputs = () => {
    setDate(1);
    setCardDetail({});
  };
  const submitCardDetails = () => {
    console.log(type);
    if (
      (!type && !cardDetail.cardno) ||
      (!type && !cardDetail.expdate) ||
      (!type && !cardDetail.cvv) ||
      (type === 'pin' && !cardDetail.pin) ||
      (type === 'billing' && !cardDetail.billingaddress) ||
      (type === 'billing' && !cardDetail.billingcity) ||
      (type === 'billing' && !cardDetail.billingcountry) ||
      (type === 'billing' && !cardDetail.billingstate) ||
      (type === 'billing' && !cardDetail.billingzip)
    ) {
      return dispatch(
        setNotify({
          type: 'error',
          title: 'Fill card information correctly',
          body: 'card details not complete'
        })
      );
    }
    console.log(cardDetail);
    return dispatch(
      addCard(
        {
          ...cardDetail,

          expirymonth:
            cardDetail.expdate && Number(cardDetail.expdate.split('/')[0]),
          expiryyear:
            cardDetail.expdate && Number(cardDetail.expdate.split('/')[1]),
          amount: plan && plan.estimatedMonthlyPament,
          redirect_url: `${window.location.origin.toString()}/verify`, //window.location.href,
          suggested_auth
        },
        action,
        clearInputs,
        first
      )
    );
  };

  const submitCardOtp = () => {
    if (!cardDetail.otp) {
      return dispatch(
        setNotify({
          type: 'error',
          title: 'Fill in Otp',
          body: 'Process require Otp'
        })
      );
    }
    return dispatch(
      validateCard(
        {
          otp: cardDetail.otp,
          transaction_reference
        },
        clearInputs,
        action,
        first
      )
    );
  };

  const onchange = e => {
    e && e.preventDefault();
    const { name, value, maxLength } = e.target;
    if (value.length <= maxLength) {
      if (name === 'expdate') {
        switch (value.length) {
          case 1:
            const newValue1 = value.split('');
            if (Number(newValue1[0]) > 1 || isNaN(newValue1[0])) {
              newValue1.pop();
            }
            return setCardDetail({
              ...cardDetail,
              [name]: newValue1.join('')
            });
          case 2:
            const newValue2 = value.split('');
            if (
              (Number(newValue2[0]) > 0 && Number(newValue2[1]) > 2) ||
              (Number(newValue2[0]) < 1 && Number(newValue2[1]) < 1) ||
              isNaN(newValue2[1])
            ) {
              newValue2.pop();
            }
            return setCardDetail({
              ...cardDetail,
              [name]: newValue2.join('')
            });
          case 3:
            const newValue3 = value.split('');
            if (newValue3[2] === '/' || isNaN(newValue3[2])) {
              newValue3.pop();
            } else {
              newValue3[2] = `/${newValue3[2]}`;
            }

            return setCardDetail({
              ...cardDetail,
              [name]: newValue3.join('')
            });
          case 5:
            const newValue4 = value.split('');
            if (
              (Number(newValue4[3]) < 1 && Number(newValue4[4]) < 1) ||
              isNaN(newValue4[4])
            ) {
              newValue4.pop();
            }
            return setCardDetail({
              ...cardDetail,
              [name]: newValue4.join('')
            });
          default:
            return setCardDetail({
              ...cardDetail,
              [name]: value
            });
        }
      }
      return setCardDetail({
        ...cardDetail,
        [name]: value
      });
    }
    if (name === 'otp') {
      return setCardDetail({
        ...cardDetail,
        [name]: value
      });
    }
    if (type === 'billing') {
      return setCardDetail({
        ...cardDetail,
        [name]: value
      });
    }
  };

  const override = css`
    display: block;
    margin: 40px auto;
  `;

  const ProgressBar = ({ val }) =>
    loading ? (
      <BarLoader
        css={override}
        width={370}
        sizeUnit={'px'}
        color={'#174a41'}
        height={4}
      />
    ) : (
      <progress className={styles.progress} max='100' value={val} />
    );

  const nextStage = type => {
    switch (type) {
      case 'pin':
        return (
          <div className={styles.main}>
            <div className={styles.title}>Enter your Pin</div>
            <div className={styles.sub}>
              Kindly fill the form below to add a card.
            </div>
            <div className={styles.input}>
              <label htmlFor=''>CARD PIN</label>
              <br />
              <input
                type='number'
                name='pin'
                value={cardDetail.pin || ''}
                // defaultValue={null}
                maxLength={4}
                placeholder='CARD PIN'
                onChange={onchange}
                className={
                  !cardDetail.pin || cardDetail.pin.length < 4
                    ? styles.error
                    : ''
                }
              />
            </div>
            <div className={styles.input}>
              <button onClick={submitCardDetails}>Enter Pin & Finish</button>
            </div>
            <div className={styles.input}>
              <ProgressBar val={25 * current} />
            </div>
          </div>
        );
      case 'otp':
        return (
          <div className={styles.main}>
            <div className={styles.title}>Enter the OTP sent to you</div>
            <div className={styles.sub}>
              Kindly fill in the OTP sent to your email or phone number.
            </div>
            <div className={styles.input}>
              <br />
              <br />
              <label htmlFor=''>ONE TIME PASSWORD</label>
              <input
                type='text'
                name='otp'
                value={cardDetail.otp || ''}
                placeholder='OTP'
                onChange={onchange}
              />
            </div>
            <div className={styles.input}>
              <button onClick={submitCardOtp}>Enter OTP</button>
            </div>
            <div className={styles.input}>
              <ProgressBar val={25 * (current + current * 0.7)} />
            </div>
          </div>
        );
      case 'url':
        return (
          <div className={styles.mainI}>
            <div className={styles.title}>Complete the process</div>
            <div className={styles.sub}>
              Kindly fill the form below to add a card.
            </div>
            <iframe id='frame' title='authUrl' src={authUrl} frameBorder='0' />
            <div className={styles.input}>
              <button
                onClick={() => {
                  try {
                    const ifrm = document.getElementById('frame');
                    const loc = ifrm.contentWindow.location.href;
                    if (loc && loc.includes('verify')) {
                      dispatch(
                        redirectCard({ flwref, reference }, action, first)
                      );
                    } else {
                      dispatch(
                        setNotify({
                          type: 'error',
                          title: 'Fill in the OTP',
                          body:
                            'Fill in the OTP sent to you then complete process'
                        })
                      );
                    }
                  } catch (error) {
                    return dispatch(
                      setNotify({
                        type: 'error',
                        title: 'Fill in the OTP',
                        body:
                          'Fill in the OTP sent to you then complete process'
                      })
                    );
                  }
                }}
              >
                Verify Card
              </button>
              <ProgressBar val={25 * current} />
            </div>
          </div>
        );
      case 'billing':
        return (
          <div className={styles.main}>
            <div className={styles.title}>Complete you Billing Details</div>
            <div className={styles.sub}>
              Kindly fill the form below to add a card.
            </div>
            <div className={styles.input}>
              <label htmlFor=''>BILLING ADDRESS</label>
              <br />
              <input
                type='text'
                name='billingaddress'
                value={cardDetail.billingaddress || ''}
                placeholder='BILLING ADDRESS'
                onChange={onchange}
                className={!cardDetail.billingaddress ? styles.error : ''}
              />
            </div>
            <div className={styles.input}>
              <label htmlFor=''>BILLING CITY</label>
              <br />
              <input
                type='text'
                name='billingcity'
                value={cardDetail.billingcity || ''}
                placeholder='BILLING CITY'
                onChange={onchange}
                className={!cardDetail.billingcity ? styles.error : ''}
              />
            </div>
            <div className={styles.input}>
              <label htmlFor=''>BILLING STATE</label>
              <br />
              <input
                type='text'
                name='billingstate'
                value={cardDetail.billingstate || ''}
                placeholder='BILLING STATE'
                onChange={onchange}
                className={!cardDetail.billingstate ? styles.error : ''}
              />
            </div>
            <div className={styles.input}>
              <label htmlFor=''>BILLING COUNTRY</label>
              <br />
              <input
                type='text'
                name='billingcountry'
                value={cardDetail.billingcountry || ''}
                placeholder='BILLING COUNTRY'
                onChange={onchange}
                className={!cardDetail.billingcountry ? styles.error : ''}
              />
            </div>
            <div className={styles.input}>
              <label htmlFor=''>BILLING ZIP CODE</label>
              <br />
              <input
                type='text'
                name='billingzip'
                value={cardDetail.billingzip || ''}
                placeholder='BILLING  ZIP CODE'
                onChange={onchange}
                className={!cardDetail.billingzip ? styles.error : ''}
              />
            </div>
            <div className={styles.input}>
              <button onClick={submitCardDetails}>Submit</button>
            </div>
            <div className={styles.input}>
              <ProgressBar val={25 * current} />
            </div>
          </div>
        );
      default:
        break;
    }
  };

  const renderPayContent = {
    0: children,
    1: (
      <div className={styles.cardCollect}>
        <div className={styles.main}>
          <div className={styles.title}>Setup Payment Plan</div>
          <div className={styles.sub}>
            Kindly fill the form below to add a card.
          </div>
          <div className={styles.input}>
            <label htmlFor=''>CARD NUMBER</label>
            <input
              type='text'
              name='cardno'
              value={cardDetail.cardno || ''}
              maxLength={19}
              pattern='\d{4}-?\d{4}-?\d{4}-?\d{4}'
              placeholder='CARD NUMBER'
              ref={inputs}
              onChange={onchange}
              className={
                !cardDetail.cardno || cardDetail.cardno.length < 16
                  ? styles.error
                  : ''
              }
            />
          </div>
          <div className={styles.two}>
            <div className={styles.input}>
              <label htmlFor=''>EXP. DATE</label>
              <input
                type='text'
                name='expdate'
                value={cardDetail.expdate || ''}
                placeholder='MM/YY'
                maxLength={5}
                onChange={onchange}
                className={
                  !cardDetail.expdate || cardDetail.expdate.length < 5
                    ? styles.error
                    : ''
                }
              />
            </div>
            <div className={styles.input}>
              <label htmlFor=''>CVV</label>
              <input
                type='number'
                name='cvv'
                placeholder='CVV'
                maxLength={3}
                value={cardDetail.cvv || ''}
                onChange={onchange}
                className={
                  !cardDetail.cvv || cardDetail.cvv.length < 3
                    ? styles.error
                    : ''
                }
              />
            </div>
          </div>
          <div className={styles.input}>
            <button onClick={() => submitCardDetails('card')}>
              Add Card
              {first && (
                <>
                  {' & Pay '}
                  &#8358; {plan && currency(plan.estimatedMonthlyPament)}
                </>
              )}
            </button>
          </div>
          <div className={styles.input}>
            <ProgressBar val={25 * current} />
          </div>

          <div className={styles.input}>
            <button className={styles.exit} onClick={action['start']}>
              Exit{' '}
            </button>
          </div>
        </div>
      </div>
    ),
    2: <div className={styles.cardCollect}>{nextStage(type)}</div>,
    3: (
      <div className={styles.cardCollect}>
        <div className={styles.main}>
          <div className={styles.title}>
            Way to go {user && user.firstname}!
          </div>
          <div className={styles.sub}>
            You’ve successfully made your first payment, click the button below
            to setup your payment date.
          </div>

          <div>
            <img src={fly} alt='' />
          </div>
          <div className={styles.input}>
            <button onClick={action['setDate']}>
              Automate Monthly Contributions
            </button>
          </div>
          <div className={styles.input}>
            <ProgressBar val={100} />
          </div>
        </div>
      </div>
    ),
    4: (
      <div className={styles.cardCollect}>
        <div className={styles.main}>
          <div className={styles.title}>Monthly Contributions</div>
          <div className={styles.sub}>
            Choose a recurring day of the month for making your contributions.
          </div>

          <div className={styles.dates}>
            {[...Array(28).keys()].map(i => (
              <p
                key={i}
                className={date === i + 1 ? styles.active : ''}
                onClick={() => setDate(i + 1)}
              >
                {i + 1}
              </p>
            ))}
          </div>
          <div className={styles.input}>
            <button
              onClick={() => dispatch(setPlanDate(date, action['start']))}
            >
              Confirm {dater(date)} of every month
            </button>
          </div>
          <div className={styles.input}>
            <ProgressBar val={100} />
          </div>
        </div>
      </div>
    )
  };

  return renderPayContent[current];
};

export default CardProcess;
