import React, { useState, useEffect } from "react";
import spinner from "../../../assets/spinnerblue.svg";
import exit from "../../../assets/exit2.svg";
import hicon from "../../../assets/hicon.svg";
import cardsImage from "../../../assets/cards.svg";
import purse2 from "../../../assets/saveTwo.svg";
import styles from "./index.module.scss";
import "react-circular-progressbar/dist/styles.css";
import currency from "../../../utils/currency";
import dateDiff from "../../../utils/dateDiff";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useMappedState, useDispatch } from "redux-react-hook";
import {
  fetchCalculation,
  generateDownPayement,
  getDownPayementStatus,
  setPlanDate
} from "../../../store/actions/onboard";
import { allCards, volutaryPay } from "../../../store/actions/card";
import Table from "../../../components/Table";
import { sendReferrals } from "../../../store/actions/auth";
import {
  getDeposits,
  getWithdrawal,
  getContratual
} from "../../../store/actions/wallet";
import CardProcess from "../../../components/CardProcess";
import SelectInput from "../../../components/SelectInput";
import SupportGuage from "../../../components/SupportGuage";
import properNum from "../../../utils/properNum";
import CurrencyInput from "../../../components/CurrencyInput";
import dater from "../../../utils/dater";
import { setNotify } from "../../../store/actions/notify";

const DashboardHome = () => {
  const dispatch = useDispatch();
  const user = useMappedState(({ auth }) => auth.currentUser);
  const contributions = useMappedState(({ wallet }) => wallet.contributions);
  const withdrawals = useMappedState(({ wallet }) => wallet.withdrawals);
  const plan = useMappedState(({ onboard }) => onboard.calculation);
  const { downPaymentDetails, downPaymentStatus } = useMappedState(
    ({ onboard }) => onboard
  );
  const baseDate = new Date().getDay() + 2;
  const { contractual, voluntary } = useMappedState(({ wallet }) => wallet);
  const deposits = useMappedState(({ wallet }) => wallet.deposits);
  const { cards, loading } = useMappedState(({ card }) => card);

  const [current, setCurrent] = useState(0);
  const [topPage, setTopPage] = useState(null);
  const [emails, setEmails] = useState("");
  const [amount, setAmount] = useState(null);
  const [method, setMethod] = useState(null);
  const [payment, setPayment] = useState({});
  const [modal, setModal] = useState(false);
  const [dateModal, setDateModal] = useState(false);
  const [date, setDate] = useState(baseDate);

  const sectionsProps = [
    { fill: "#FF3333", stroke: "#FF3333" },
    { fill: "#FF866B", stroke: "#FF866B" },
    { fill: "#FFD66B", stroke: "#FFD66B" },
    { fill: "#BED244", stroke: "#BED244" },
    { fill: "#54CF6F", stroke: "#54CF6F" }
  ];
  useEffect(() => {
    dispatch(fetchCalculation());
    dispatch(allCards());
    dispatch(getDeposits());
    dispatch(getWithdrawal());
    dispatch(getContratual());
    dispatch(generateDownPayement());
    dispatch(getDownPayementStatus());
    const timeout = setInterval(() => {
      if (user.hasDownpayment === "yes") {
        dispatch(generateDownPayement());
        dispatch(getDownPayementStatus());
      }
    }, 5000);

    return () => {
      clearInterval(timeout);
    };
  }, [user, dispatch]);

  const action = {
    start: () => setCurrent(0),
    first: () => setCurrent(1),
    card: () => setCurrent(2),
    success: () => setCurrent(3),
    setDate: () => setCurrent(4)
  };
  const countPercent = contributions
    ? ((new Date() - new Date(contributions.startDate)) /
        (new Date(contributions.countDownDate) -
          new Date(contributions.startDate))) *
      100
    : 0;

  const sendReferral = e => {
    e && e.preventDefault();
    dispatch(sendReferrals(emails.replace(/\s/g, "").split(",")));
    return setEmails("");
  };
  const cardCollection = cards.map(card => ({
    value: card._id,
    label: `${card.type} ${card.last4Digits}`
  }));

  const handleSelect = data => {
    setPayment({
      ...payment,
      cardID: data.value
    });
    return setMethod(data);
  };
  const payNow = e => {
    e && e.preventDefault();
    return dispatch(
      volutaryPay(payment, () => {
        setPayment({});
        setMethod(null);
        setAmount("");
      })
    );
  };

  const renderTopPage = page => {
    const topPages = {
      contractual: (
        <div className={styles.dash}>
          <div className={styles.left}>
            <div className={styles.card}>
              <div className={styles.title}>Details</div>

              <div className={styles.bud}>
                <div className={styles.top}>TOTAL AMOUNT</div>
                <div className={styles.bold}>
                  &#8358; {currency(contractual.totalContractualContribution)}
                </div>
              </div>

              <div className={styles.bud}>
                <div className={styles.top}>MONTHLY AMOUNT</div>
                <div className={styles.bold}>
                  &#8358; {currency(contractual.monthlyAmount)}
                </div>
              </div>

              <div className={styles.bud}>
                <div className={styles.top}>DATE OF 1ST CONTRIBUTION</div>
                <div className={styles.normal}>
                  {contractual.firstPaymentDate}
                </div>
              </div>
              <div className={styles.bud}>
                <div className={styles.top}>
                  DATE OF NEXT CONTRIBUTION{" "}
                  <button onClick={() => setDateModal(true)}>
                    <i className="lni lni-pencil" />
                  </button>
                </div>
                <div className={styles.normal}>
                  {contractual.nextDateToBeBilled}
                </div>
              </div>

              <div className={styles.bud}>
                <div className={styles.topOrange}>
                  ESTIMATED COMPLETION DATE
                </div>
                <div className={styles.normal}>{contractual.maturityDate}</div>
              </div>
            </div>
            <button className={styles.back} onClick={() => setTopPage(null)}>
              Back
            </button>
          </div>
          <div className={styles.right}>
            <div className={styles.table}>
              <Table
                title="Contribution History"
                showTitle
                header={["Amount", "date", "reference", "point"]}
                data={contractual.contributionHistory || []}
                conditions={{
                  double: "status",
                  badge: "successful"
                }}
                dataType={[
                  { depositAmount: "double" },
                  { depositDate: "text" },
                  { reference: "truncate" },
                  { pointsGained: "boldText" }
                ]}
              />
            </div>
          </div>
        </div>
      ),
      voluntary: (
        <div className={styles.dash}>
          <div className={styles.left}>
            <div className={styles.card}>
              <div className={styles.title}>Details</div>

              <div className={styles.bud}>
                <div className={styles.top}>TOTAL AMOUNT</div>
                <div className={styles.bold}>
                  &#8358; {currency(voluntary.totalVoluntaryContribution)}
                </div>
              </div>

              <div className={styles.bud}>
                <div className={styles.top}>MONTHLY AMOUNT</div>
                <div className={styles.bold}>
                  &#8358; {currency(voluntary.monthlyAmount)}
                </div>
              </div>

              <div className={styles.bud}>
                <div className={styles.topOrange}>
                  ESTIMATED COMPLETION DATE
                </div>
                <div className={styles.normal}>{voluntary.maturityDate}</div>
              </div>
            </div>
            <button className={styles.back} onClick={() => setTopPage(null)}>
              Back
            </button>
          </div>
          <div className={styles.right}>
            <div className={styles.table}>
              <Table
                title="Contribution History"
                showTitle
                header={["Amount", "date", "reference", "point"]}
                data={voluntary.voluntaryContributionHistory || []}
                conditions={{
                  double: "status",
                  badge: "successful"
                }}
                dataType={[
                  { depositAmount: "double" },
                  { depositDate: "text" },
                  { reference: "truncate" },
                  { pointsGained: "boldText" }
                ]}
              />
            </div>
          </div>
        </div>
      )
    };

    return topPages[page];
  };
  const renderDash = () => (
    <div className={styles.dash}>
      <div className={styles.left}>
        <div
          className={`${styles.pill} ${styles.darkblue}`}
          onClick={() => setTopPage("contractual")}
        >
          <div className={styles.text}>
            <span>TOTAL CONTRACTUAL EQUITY</span>
            <div>
              &#8358;{" "}
              {contributions.equityContribution &&
                currency(contributions.equityContribution.paid)}
            </div>
          </div>

          <span className=""> ▶︎ </span>
        </div>
        <div
          className={`${styles.pill} ${styles.lightblue}`}
          onClick={() => setTopPage("voluntary")}
        >
          <div className={styles.text}>
            <span>VOLUNTARY EQUITY</span>
            <div>
              &#8358;{" "}
              {contributions.voluntaryContribution &&
                currency(contributions.voluntaryContribution)}
            </div>
          </div>

          <span className=""> ▶︎ </span>
        </div>
        <div className={`${styles.pill} ${styles.green}`}>
          <div className={styles.text}>
            <span>WELCOME & REFERRAL BONUS</span>
            <div>
              &#8358;{" "}
              {contributions.matchingBonus &&
                currency(contributions.matchingBonus)}
            </div>
          </div>
          <div />
          <div />
        </div>
        {user.hasDownpayment === "yes" && (
          <div className={`${styles.pill} ${styles.blue}`}>
            <div className={styles.text}>
              <span>DOWN PAYMENT</span>
              <div>
                &#8358;{" "}
                {user.downPaymentAmount && currency(user.downPaymentAmount)}
              </div>
            </div>

            <div />
          </div>
        )}
        {withdrawals.length > 0 &&
          <div className={`${styles.pill} ${styles.lightblue}`}>
          <div className={styles.text}>
            <span>Repayment balance (Due on {`${new Date(withdrawals[withdrawals.length -1].returnDate).toDateString()}`})</span>
            <div>
              &#8358;{" "}
              {
                currency(withdrawals[withdrawals.length -1].amountWithInterest)}
            </div>
          </div>

          <div />
        </div>
        }
        <div className={styles.pill}>
          <div className={styles.text}>
            <span>TOTAL EQUITY</span>
            <div>
              &#8358;{" "}
              {contributions.totalContribution &&
                currency(contributions.totalContribution)}
            </div>
          </div>

          <div />
        </div>

        <div className={styles.save}>
          <div className={styles.top}>
            <img src={purse2} alt="" /> Save now
          </div>
          <div className={styles.sub}>
            Make an instant equity or voluntary contribution.
          </div>
          <form onSubmit={payNow}>
            <label htmlFor="">How much do you want to contribute?</label>

            <CurrencyInput
              name="amount"
              num={amount}
              onchange={e => {
                e.preventDefault();
                setAmount(e.target.value);
                return setPayment({
                  ...payment,
                  [e.target.name]: properNum(e.target.value)
                });
              }}
            />

            <SelectInput
              small
              noborder
              blue
              placeholder="Select a Payment Method"
              name="paymentMethod"
              value={method}
              changer={handleSelect}
              data={cardCollection}
            />
            {loading ? (
              <img
                style={{
                  height: "45px"
                }}
                src={spinner}
                alt="loader"
              />
            ) : (
              <input type="submit" value="Save now" />
            )}
          </form>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.bars}>
          <div className={styles.chart}>
            <div className={styles.top}>
              <div className={styles.wrapper}>
                <svg width={250} height={90}>
                  <SupportGuage
                    width={250}
                    height={200}
                    sections={sectionsProps}
                    point={contributions.morgagePoints || 0}
                    total={contributions.totalMortgagePoints || 100}
                  />
                </svg>
                <div className={styles.point}>
                  {contributions.morgagePoints || 0}
                </div>
              </div>
              <span>{contributions.message}</span>
            </div>
            <div className={styles.bottom}>YOUR SAVINGS SCORE</div>
          </div>
          <div className={styles.ref}>
            <div className={styles.top}>Refer a Friend</div>
            <div className={styles.sub}>
              Invite a friend to FirstHomes Africa and gain 5 mortgage points!
            </div>
            <form onSubmit={sendReferral}>
              <input
                type="text"
                value={emails}
                onChange={e => setEmails(e.target.value)}
                placeholder="Email Address"
              />
              <input type="submit" value="Invite" />
            </form>
          </div>
        </div>
        <div className={styles.table}>
          <Table
            title="Transaction History"
            showTitle
            header={["Amount", "date", "type", "point"]}
            data={deposits}
            conditions={{
              double: "status",
              badge: "successful"
            }}
            dataType={[
              { depositAmount: "double" },
              { depositDate: "text" },
              { type: "text" },
              { pointsGained: "boldText" }
            ]}
          />
        </div>
      </div>
    </div>
  );
  return (
    <div className={styles.home}>
      <div className={styles.head}>
        <div className={styles.welcome}>
          {topPage ? (
            topPage === "contractual" ? (
              "Contractual Equity"
            ) : (
              "Voluntary Equity"
            )
          ) : (
            <>Hi {user && user.firstname}, </>
          )}
        </div>
        <div className={styles.time}>
          <div
            style={{
              width: "38.59px"
            }}
          >
            <CircularProgressbarWithChildren
              value={countPercent}
              background
              styles={{
                path: {
                  stroke: "#174a41"
                },
                trail: {
                  fill: "#174a41",
                  opacity: "0.1"
                },
                background: {
                  fill: "#174a41",
                  opacity: "0.1"
                }
              }}
            >
              <div className={styles.icon}>
                <img src={hicon} alt="icon" />
              </div>
            </CircularProgressbarWithChildren>
          </div>
          <div className={styles.date}>
            {contributions && contributions.countDownDate
              ? dateDiff(contributions.countDownDate, new Date(), "month")
              : "0"}
            Mth :{" "}
            {contributions && contributions.countDownDate
              ? dateDiff(contributions.countDownDate, new Date(), "day")
              : "0"}
            D
          </div>
        </div>
      </div>
      <div style={{ flex: 1 }}>
        {user &&
        downPaymentStatus &&
        user.hasMadeFirstPayment &&
        (user.hasDownpayment !== "yes" ||
          (user.hasDownpayment === "yes" &&
            downPaymentStatus.paymentStatus === "FULL")) ? (
          topPage ? (
            renderTopPage(topPage)
          ) : (
            renderDash()
          )
        ) : (
          <CardProcess current={current} action={action} first>
            {
              <>
                {downPaymentStatus &&
                  user &&
                  user.hasDownpayment === "yes" &&
                  downPaymentStatus.paymentStatus !== "FULL" && (
                    <div
                      className={styles.downpay}
                      onClick={() => setModal(true)}
                    >
                      <span>Your down payment</span>
                      <div>
                        &#8358;{" "}
                        {downPaymentStatus.paymentStatus === "PARTIAL"
                          ? `${currency(
                              downPaymentStatus.totalPaidSoFar
                            )} / ${currency(
                              downPaymentStatus.downPaymentAmount
                            )}`
                          : downPaymentStatus &&
                            currency(downPaymentStatus.downPaymentAmount || 0)}
                      </div>
                    </div>
                  )}
                <div className={styles.boost}>
                  <div className={styles.top}>
                    <div className={styles.details}>
                      {downPaymentStatus &&
                      user &&
                      user.hasDownpayment === "yes" &&
                      downPaymentStatus.paymentStatus !== "FULL" ? (
                        <div>Make your Down Payment</div>
                      ) : (
                        <div>Lets give you a boost</div>
                      )}
                      {((user && user.hasDownpayment !== "yes") ||
                        downPaymentStatus.paymentStatus === "FULL") && (
                        <span>
                          Make your first monthly contribution and get a 100%
                          welcome bonus while offer lasts
                        </span>
                      )}
                    </div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.head}>
                      {downPaymentStatus &&
                      user &&
                      user.hasDownpayment === "yes" &&
                      downPaymentStatus.paymentStatus !== "FULL"
                        ? "Account Detail"
                        : "Start Saving"}
                    </div>
                    <div className={styles.body}>
                      <img
                        src={cardsImage}
                        alt="savings button"
                        onClick={
                          downPaymentStatus &&
                          user &&
                          user.hasDownpayment === "yes" &&
                          downPaymentStatus.paymentStatus !== "FULL"
                            ? () => setModal(true)
                            : plan
                            ? action["first"]
                            : () =>
                                dispatch(
                                  setNotify({
                                    type: "error",
                                    title: "You have not set up your housing plan",
                                    body: "Go to Account -> modify plan"
                                  })
                                )
                        }
                      />
                      {downPaymentStatus &&
                      user &&
                      user.hasDownpayment === "yes" &&
                      downPaymentStatus.paymentStatus !== "FULL" ? (
                        <p>
                          You’re yet to pay your down payment, tap this card to
                          view account to pay into
                        </p>
                      ) : (
                        <p>
                          You’re yet to add a payment method, tap this card to
                          get started
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </>
            }
          </CardProcess>
        )}
      </div>
      {modal && (
        <div className={styles.downModal}>
          <div className={styles.close} onClick={() => setModal(false)}></div>
          <div className={styles.body}>
            <span className={styles.closex} onClick={() => setModal(false)}>
              <img src={exit} alt="exit" />
            </span>
            <div>
              <div className={styles.title}>Make Down Payment</div>
              <div className={styles.sub}>
                Make a bank transfer into the account number below.
              </div>
              <div className={styles.subtitle}>ACCOUNT DETAILS</div>
              <div className={styles.group}>
                <div>
                  <div className={styles.item}>
                    <div>ACCOUNT NAME</div>
                    <div className={styles.cont}>
                      {downPaymentDetails && downPaymentDetails.accountName}
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div>BANK </div>
                    <div className={styles.cont}>
                      {downPaymentDetails && downPaymentDetails.bankName}
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.item}>
                    <div>ACCOUNT NUMBER</div>
                    <div className={styles.cont}>
                      {downPaymentDetails && downPaymentDetails.accountNumber}
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div>AMOUNT</div>
                    <div className={styles.cont}>
                      &#8358;{" "}
                      {downPaymentStatus &&
                        currency(downPaymentStatus.leftToBePaid)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {dateModal && (
        <div className={styles.downModal}>
          <div
            className={styles.close}
            onClick={() => setDateModal(false)}
          ></div>
          <div className={styles.body}>
            <span className={styles.closex} onClick={() => setDateModal(false)}>
              <img src={exit} alt="exit" />
            </span>
            <div>
              <div className={styles.title}>Edit monthly repayment date</div>
              <div className={styles.sub}>
                Choose a recurring day of the month for making your
                contributions.
              </div>
              <div className={styles.kit}>
                <div className={styles.dates}>
                  {[...Array(28).keys()].map(i => (
                    <p
                      className={
                        date === i + 1
                          ? styles.active
                          : baseDate > i + 1
                          ? styles.inactive
                          : ""
                      }
                      onClick={() => (baseDate > i + 1 ? null : setDate(i + 1))}
                    >
                      {i + 1}
                    </p>
                  ))}
                </div>
                <div className={styles.input}>
                  <button
                    onClick={() => {
                      dispatch(setPlanDate(date, action["start"]));
                      return setDateModal(false);
                    }}
                  >
                    Confirm {dater(date)} of every month
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default DashboardHome;
