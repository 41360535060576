import React from "react";
import styles from "./index.module.scss";

const ImgDesc = ({ title, desc, sub, image, right }) => {
  return (
    <div className={`${styles.group} ${right ? styles.groupright : ''}`} >
      {!right && (
        <div className={styles.side}>
          <img src={image} alt="desc" />
        </div>
      )}
      <div className={styles.mid}>
        <div>
          <div className={styles.title}>{title}</div>
          {sub && <div className={styles.sub}>{sub}</div>}
          <div className={styles.desc}>{desc}</div>
        </div>
      </div>

      {right && (
        <div className={styles.side}>
          <img src={image} alt="desc" />
        </div>
      )}
    </div>
  );
};

export default ImgDesc;
