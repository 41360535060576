import Folder from '../../components/Folder';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import React from 'react';
import Whatsapp from '../../components/Whatsapp';
import android from '../../assets/playstore.jpg';
import bonus from '../../assets/bonus.png';
import ios from '../../assets/appstore.svg';
import phonebg from '../../assets/phone.png';
import styles from './index.module.scss';

const Bonus = () => {
  return (
    <div className={styles.bonus}>
      <Whatsapp />
      <Nav showBackground address />
      <div className={styles.main}>
        <div className={styles.banner}>
          <div className={styles.image}>
            <img src={bonus} alt='suprised man' />
          </div>
          <div className={styles.detail}>
            <div className={styles.title}>
              Boost your Equity by up to N500K!
            </div>
            <div className={styles.desc}>
              Sign up now and win 100% welcome bonus of up to N500K for your
              first deposit. It doesn’t stop there, share the goodies with your
              friends and family and get N5000 equity boost per referral.
            </div>

            <div className={styles.links}>
              <Link to=''>Learn More</Link>
              <Link to='signup' className={styles.dark}>
                Get Started
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.steps}>
          <div className={styles.title}>3 steps to winning your bonus.</div>
          <div className={styles.grid}>
            <div className={styles.step}>
              <div className={styles.number}>1</div>
              <div className={styles.top}>Sign up</div>
              <div className={styles.sub}>
                Sign up and get 100% welcome bonus of your first deposit to
                maximum of N500K
              </div>
            </div>
            <div className={styles.step}>
              <div className={styles.number}>2</div>
              <div className={styles.top}>Refer friends and family</div>
              <div className={styles.sub}>
                Share the good news to avoid squatters, Join our referral
                program and get your unique referral code that you can share
                with your friends and family.
              </div>
            </div>
            <div className={styles.step}>
              <div className={styles.number}>3</div>
              <div className={styles.top}>Build more equity</div>
              <div className={styles.sub}>
                For every sign up with your referral code you get an equity
                boost of N5000. Terms and conditions applied.
              </div>
            </div>
          </div>
        </div>

        <div className={styles.download}>
          <div>
            <img className={styles.ph} src={phonebg} alt='phone' />
          </div>
          <div className={styles.store}>
            <div>
              Download the app and start your journey to financial freedom while
              offer lasts!
            </div>
            <div className={styles.btns}>
              <Link to='' onClick={e => e.preventDefault()}>
                <img src={ios} alt='ios' />
                <span>coming soon!</span>
              </Link>
              <a
                href='https://play.google.com/store/apps/details?id=com.firsthome'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={android} alt='android' />
              </a>
            </div>
          </div>
        </div>

        <div className={styles.faq}>
          <div>FAQs</div>
          <div className={styles.part}>
            <div>
              <Folder
                title='Q: How long will the bonus program run for?'
                body='3 months'
              />
              <Folder
                title='Q: How many referrals can I make?'
                body='As many as you can, provided they are within your network of friends and family and they meet the eligibility criteria'
              />
              <Folder
                title='Q: What is the maximum amount of welcome bonus I can earn on the platform?'
                body='N500,000 one time welcome bonus'
              />
              <Folder
                title='Q: When do I get my referral reward?'
                body='Maximum of 7 days from date of account funding by the referee'
              />
              <Folder
                title='Q: When do I get my welcome bonus?'
                body='Immediately you sign up'
              />
            </div>
            <div>
              <Folder
                title='Q: How much can I earn on referral bonus?'
                body='You can earn multiples of N5,000 on referees that have completed their sign up process using your referral code'
              />
              <Folder
                title='Q: Who can I refer to FirstHomes Africa referral program?'
                body='People within your network'
              />
              <Folder
                title='Q: How does the referral system work?'
                body='The choice of referral code used is the right of the referee. The referral code used during the sign-up process is final and the bonus will be credited to the referrer'
              />
              <Folder
                title="Q: Do I get a welcome bonus if I don't use a referral code to sign up?"
                body='Yes'
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Bonus;
