import React, { useEffect } from 'react';

import styles from './index.module.scss';
import './index.module.scss';
let icon;
const Verify = () => {
  useEffect(() => {
    setInterval(() => {
      icon = document.getElementsByClassName('b24-widget-button-wrapper');
      if (icon.length) {
        if (icon[0].style.display !== 'none') {
          icon[0].style.display = 'none';
        }
      }
    }, 1000);
    return () => {
      icon[0].style.display = 'block';
    };
  }, []);
  return (
    <div className={styles.verify}>
      <div> Click Button to verify the Card</div>
    </div>
  );
};

export default Verify;
