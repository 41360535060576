import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useMappedState } from "redux-react-hook";
export default ({ component: Component, ...configs }) => {
  const user = useMappedState(({ auth }) => auth.currentUser);
  return (
    <Route
      {...configs}
      render={props => (user ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
};
