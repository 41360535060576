import 'react-phone-input-2/dist/style.css';

import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import ReactPhoneInput from 'react-phone-input-2';
import Select from 'react-select';
import banner1 from '../../assets/signupbanner.png';
import includesAll from '../../utils/includesAll';
import { setNotify } from '../../store/actions/notify';
import { signupUser } from '../../store/actions/auth';
import spinner from '../../assets/spinner.svg';
import styles from './index.module.scss';
import { useDispatch } from 'redux-react-hook';
import { useMappedState } from 'redux-react-hook';

const Signup = ({ history }) => {
  const [details, setDetails] = useState({});
  const [show, setshow] = useState(true);
  const [sect, setSect] = useState({});
  const [mon, setMon] = useState(1);

  const monthDays = {
    1: {
      name: 'Jan',
      days: 31
    },
    2: {
      name: 'Feb',
      days: !sect.year || sect.year.value % 4 ? 28 : 29
    },
    3: {
      name: 'Mar',
      days: 31
    },
    4: {
      name: 'Apr',
      days: 30
    },
    5: {
      name: 'May',
      days: 31
    },
    6: {
      name: 'Jun',
      days: 30
    },
    7: {
      name: 'Jul',
      days: 31
    },
    8: {
      name: 'Aug',
      days: 31
    },
    9: {
      name: 'Sep',
      days: 30
    },
    10: {
      name: 'Oct',
      days: 31
    },
    11: {
      name: 'Nov',
      days: 30
    },
    12: {
      name: 'Dec',
      days: 31
    }
  };
  const dispatch = useDispatch();
  const setDate = dateOfBirth => {
    setDetails({
      ...details,
      dateOfBirth
    });
  };
  const onChange = e => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value
    });
  };
  const user = useMappedState(state => state.auth);

  const submit = e => {
    e.preventDefault();

    if (
      !includesAll(Object.keys(details), [
        'firstname',
        'lastname',
        'email',
        'phoneNumber',
        'dateOfBirth'
      ])
    ) {
      return dispatch(
        setNotify({
          type: 'error',
          title: 'All fields required',
          body: 'Insert all fields correctly'
        })
      );
    }

    if (!/^[a-zA-Z- ]+$/.test(details.firstname)) {
      return dispatch(
        setNotify({
          type: 'error',
          title: 'First name error',
          body: 'First name must not contain a number'
        })
      );
    }

    if (!/^[a-zA-Z- ]+$/.test(details.lastname)) {
      return dispatch(
        setNotify({
          type: 'error',
          title: 'Last name error',
          body: 'Last name must not contain a number'
        })
      );
    }
    dispatch(
      signupUser(
        {
          ...details,
          email: details.email.toLowerCase(),
          dateOfBirth: new Date(details.dateOfBirth).toISOString().split('T')[0]
        },
        history
      )
    );
  };

  const onSelect = (name, data) => {
    setSect({ ...sect, [name]: data });

    switch (name) {
      case 'day':
        if (sect.year && sect.month) {
          return setDate(
            `${sect.year && sect.year.value}-${sect.month &&
              sect.month.value.padStart(2, '0')}-${data.value.padStart(2, '0')}`
          );
        }
        break;
      case 'month':
        setMon(data.value);
        if (sect.day && sect.year) {
          return setDate(
            `${sect.year && sect.year.value}-${data.value.padStart(
              2,
              '0'
            )}-${sect.day && sect.day.value.padStart(2, '0')}`
          );
        }
        break;
      case 'year':
        if (sect.day && sect.month) {
          return setDate(
            `${data.value}-${sect.month &&
              sect.month.value.padStart(2, '0')}-${sect.day &&
              sect.day.value.padStart(2, '0')}`
          );
        }
        break;

      default:
        break;
    }
  };

  const days = [];
  const months = [];
  const years = [];

  [...new Array(monthDays[mon].days).keys()].map(ct =>
    days.push({ value: `${ct + 1}`, label: `${ct + 1}` })
  );

  [
    ...new Array(
      new Date().getFullYear() - 17 - (new Date().getFullYear() - 50)
    ).keys()
  ].map(ct =>
    years.push({
      value: `${ct + (new Date().getFullYear() - 50)}`,
      label: `${ct + (new Date().getFullYear() - 50)}`
    })
  );

  Object.keys(monthDays).map(ct =>
    months.push({ value: `${ct}`, label: `${monthDays[ct].name}` })
  );

  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: 'white',
      minHeight: '60px',
      border: '1px solid #03684E !important',
      boxShadow: '#03684E !important'
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: '14px',
      lineHeight: '25px',
      padding: '0 10px',
      color: 'rgba(62, 76, 89, .4)'
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      fontSize: '20px',
      lineHeight: '25px',
      color: '#03684E',
      padding: '10px'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        padding: '20px',
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? '#03684E'
          : isFocused
          ? '#e6f0ed'
          : null,
        color: isDisabled ? '#ccc' : isSelected ? 'white' : '#03684E',
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor:
            !isDisabled && (isSelected ? 'transparent' : '#e6f0ed')
        }
      };
    }
  };

  return (
    <div className={styles.signup}>
      <Nav onlyHome light />
      <div className={styles.main}>
        <div
          className={styles.left}
          style={{
            background: `url(${banner1}) center 40%`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div className={styles.text}>
            For those on a mission to own a home, sign up for a welcome bonus.
            <div>
              By signing up, you agree to our{' '}
              <Link to='/terms'>Terms of Service</Link> and{' '}
              <Link to='/privacy'>Privacy Policy.</Link>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.formGroup}>
            <div className={styles.head}>
              Already have an account? <Link to='/signin'>Sign in</Link>
            </div>
            {/* <div className={styles.headDash}>
              <hr />
              <p>Sign up with</p>{" "}
            </div>
            <div className={styles.social}>
              <button className={styles.googleBtn}>
                {" "}
                <i className="lni-google" /> Google
              </button>
              <button className={styles.faceBtn}>
                <i className="lni-facebook-filled" /> Facebook
              </button>
            </div>
            <div className={styles.headDash}>
              <hr />
              <p>Or </p>
            </div> */}

            <form onSubmit={submit}>
              <div className={styles.divide}>
                <input
                  type='text'
                  pattern='[ A-Za-z-]*'
                  name='firstname'
                  title='First name must not contain a number'
                  onChange={onChange}
                  placeholder='First name'
                />
                <input
                  type='text'
                  name='lastname'
                  pattern='[ A-Za-z-]*'
                  title='Last name must not contain a number'
                  value={details.lastname || ''}
                  onChange={onChange}
                  placeholder='Last name'
                />
              </div>
              <input
                type='email'
                name='email'
                onChange={onChange}
                placeholder='Email address'
              />
              {/* <input
                type="text"
                name="dateOfBirth"
                onChange={onChange}
                onFocus={e => (e.target.type = "date")}
                onBlur={e => (e.target.type = "text")}
                placeholder="Date of Birth"
              />  */}
              {show && (
                <input
                  onFocus={() => setshow(false)}
                  readOnly
                  value={
                    (details.dateOfBirth &&
                      `${new Date(details.dateOfBirth).toDateString()}`) ||
                    'Date of birth'
                  }
                />
              )}
              {!show && (
                <div className={styles.picker}>
                  <div className={styles.select}>
                    <Select
                      name='days'
                      onChange={data => onSelect('month', data)}
                      value={sect.month}
                      placeholder='Month'
                      options={months}
                      styles={colourStyles}
                      required={true}
                      //blurInputOnSelect={true}
                      //isSearchable={false}
                    />
                  </div>
                  <div className={styles.select}>
                    <Select
                      name='days'
                      onChange={data => onSelect('day', data)}
                      value={sect.day}
                      placeholder='Day'
                      options={days}
                      styles={colourStyles}
                      required={true}
                      //blurInputOnSelect={true}
                      // isSearchable={false}
                    />
                  </div>
                  <div className={styles.select}>
                    <Select
                      name='days'
                      onChange={data => onSelect('year', data)}
                      value={sect.year}
                      placeholder='Year'
                      options={years}
                      styles={colourStyles}
                      required={true}
                      // blurInputOnSelect={true}
                      //  isSearchable={false}
                    />
                  </div>
                  {/* <input
                type="number"
                maxLength="11"
                onInput={({ target }) => {
                  if (target.value.length > target.maxLength) {
                    target.value = target.value.slice(0, target.maxLength);
                  }
                }}
                pattern="[0-9]*"
                inputMode="numeric"
                name="phoneNumber"
                onChange={onChange}
                placeholder="month"
              /> */}
                </div>
              )}

              {/* <input
                type="number"
                maxLength="11"
                onInput={({ target }) => {
                  if (target.value.length > target.maxLength) {
                    target.value = target.value.slice(0, target.maxLength);
                  }
                }}
                pattern="[0-9]*"
                inputMode="numeric"
                name="phoneNumber"
                onChange={onChange}
                placeholder="Phone Number"
              /> */}

              {/* <PhoneInput
                country="NG"
                placeholder="Phone Number"
                value={details.phoneNumber}
                onChange={value =>
                  setDetails({ ...details, phoneNumber: value })
                }
              /> */}
              <div className={styles.phoneBody}>
                <ReactPhoneInput
                  defaultCountry={'ng'}
                  inputClass={styles.phone}
                  buttonClass={styles.phoneBtn}
                  placeholder='Phone Number'
                  value={details.phoneNumber}
                  //masks={{ ng: "+... ...-...-...." }}
                  onChange={value =>
                    setDetails({ ...details, phoneNumber: value })
                  }
                />
              </div>

              <button
                type='submit'
                className={styles.submit}
                value={spinner}
                disabled={user.loading}
              >
                {user.loading ? (
                  <img src={spinner} alt='' />
                ) : (
                  <span>SIGN UP</span>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
